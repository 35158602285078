export const downloadCsvTemplate = () => {
  const data = [
    [
      'Email of Customer',
      'Account Name',
      'Account Number',
      'Venue Name',
      'Address City',
      'Address Line 1',
      'Address Line 2',
      'Address Post Code',
      'Delivery Days',
      'Minimum Order',
      'Delivery Fee',
      'Order Cut-Off Time',
      'Price File',
    ],
  ]

  const csvContent = data.map(row => row.join(',')).join('\n')

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = 'customer template.csv'

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
