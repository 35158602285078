import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { API } from 'aws-amplify'
import './DashboardStat.css'
import styled from 'styled-components/macro'

const DashboardStat = ({ StatTitle, uniqueId, isOrdersReceived }) => {
  const [currentValue, setCurrentValue] = useState(0)
  const [previousValue, setPreviousValue] = useState(0)

  const [sizePoundBlock, setSizePoundBlock] = useState(55)
  const [fontSizePoundBlock, setFontSizePoundBlock] = useState(35)

  const account = useSelector(state => state.auth.account)
  let supplierId = account?.supplier[0]._id

  const fetchTotalOrdersReceived = async () => {
    try {
      const response = await API.get('api2', `suppliers/${supplierId}/orders`)
      return response.orders.length
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  const fetchTotalRevenueOnOMS = async () => {
    try {
      const response = await API.get('api2', `suppliers/${supplierId}/orders`)
      let totalRevenue = 0
      response.orders.forEach(item => {
        if (!isNaN(item.OMSPrice)) {
          totalRevenue += +item.OMSPrice
        }
      })
      return totalRevenue
    } catch (err) {
      console.error(err)
      return 0
    }
  }
  const getOrders = async () => {
    const result = await fetchTotalOrdersReceived()
    setCurrentValue(result)
  }
  const getTotalPrice = async () => {
    const result = await fetchTotalRevenueOnOMS()
    setCurrentValue(result)
  }

  useEffect(() => {
    if (isOrdersReceived) {
      getOrders()
    } else {
      getTotalPrice()
    }
  }, [])

  useEffect(() => {
    if (isOrdersReceived) {
      setInterval(() => {
        getOrders()
      }, 5000)
    } else {
      setInterval(() => {
        getTotalPrice()
      }, 5000)
    }
  }, [])

  useEffect(() => {
    const targetNumber = currentValue - previousValue

    if (currentValue >= previousValue) return

    const countdownTimer = setInterval(() => {
      setPreviousValue(currentValue)
      setCurrentValue(prev => {
        if (prev < targetNumber) {
          return prev + 1
        } else {
          clearInterval(countdownTimer)
          return prev
        }
      })
    }, 1000)

    return () => clearInterval(countdownTimer)
  }, [currentValue, previousValue])

  const formatNumberWithCommas = number => {
    return number.toLocaleString()
  }

  const updateTimeSegment = (segmentElement, timeValue, isChanged) => {
    const segmentDisplayTop = segmentElement.querySelector(
      '.segment-display__top',
    )
    const segmentDisplayBottom = segmentElement.querySelector(
      '.segment-display__bottom',
    )
    const segmentOverlay = segmentElement.querySelector('.segment-overlay')
    const segmentOverlayTop = segmentOverlay.querySelector(
      '.segment-overlay__top',
    )
    const segmentOverlayBottom = segmentOverlay.querySelector(
      '.segment-overlay__bottom',
    )

    if (!isChanged) {
      segmentDisplayTop.textContent = timeValue
      segmentDisplayBottom.textContent = timeValue
      return
    }

    segmentOverlay.classList.add('flip')
    segmentDisplayTop.textContent = timeValue
    segmentOverlayBottom.textContent = timeValue

    const finishAnimation = () => {
      segmentOverlay.classList.remove('flip')
      segmentDisplayBottom.textContent = timeValue
      segmentOverlayTop.textContent = timeValue

      segmentOverlay.removeEventListener('animationend', finishAnimation)
    }

    segmentOverlay.addEventListener('animationend', finishAnimation)
  }

  const adjustTimeSegmentSize = timeGroup => {
    const maxSegments = 5
    const baseFontSize = 35
    const baseWidth = 50
    const totalSegments = timeGroup.childElementCount

    if (totalSegments > maxSegments) {
      const scaleFactor = maxSegments / totalSegments
      const newFontSize = baseFontSize * scaleFactor
      const newWidth = baseWidth * scaleFactor
      setSizePoundBlock(newWidth)
      setFontSizePoundBlock(newFontSize)

      timeGroup.querySelectorAll('.time-segment').forEach(segment => {
        segment.style.fontSize = `${newFontSize}px`
        segment.style.width = `${newWidth}px`
      })

      timeGroup.querySelectorAll('.segment-overlay').forEach(segment => {
        segment.style.width = `${newWidth}px`
      })
    } else {
      timeGroup.querySelectorAll('.time-segment').forEach(segment => {
        segment.style.fontSize = `${baseFontSize}px`
        segment.style.width = `${baseWidth}px`
      })
      timeGroup.querySelectorAll('.segment-overlay').forEach(segment => {
        segment.style.width = `${baseWidth}px`
      })
    }
  }

  const updateCountSection = (timeValue, previousValue) => {
    const sectionElement = document.getElementById(uniqueId)
    const timeGroup = sectionElement.querySelector('.time-group')

    let timeString = timeValue.toString()
    let previousString = previousValue.toString()

    if (!isOrdersReceived) {
      timeString = formatNumberWithCommas(timeValue).toString()
      previousString = formatNumberWithCommas(previousValue).toString()
    }

    while (timeGroup.childElementCount > timeString.length) {
      timeGroup.removeChild(timeGroup.lastChild)
    }

    while (timeGroup.childElementCount < timeString.length) {
      const timeSegment = document.createElement('div')
      timeSegment.classList.add('time-segment')
      timeSegment.innerHTML = `
                <div class="segment-display">
                    <div class="segment-display__top">0</div>
                    <div class="segment-display__bottom">0</div>
                    <div class="segment-overlay">
                        <div class="segment-overlay__top">0</div>
                        <div class="segment-overlay__bottom">0</div>
                    </div>
                </div>
            `
      timeGroup.appendChild(timeSegment)
    }

    for (let i = 0; i < timeString.length; i++) {
      const segmentElement = timeGroup.children[i]
      const digitValue = timeString[i]
      const previousDigitValue = previousString[i] || '0'

      const isChanged = digitValue !== previousDigitValue
      updateTimeSegment(segmentElement, digitValue, isChanged)
    }
    adjustTimeSegmentSize(timeGroup)
  }

  useEffect(() => {
    updateCountSection(currentValue, previousValue)
  }, [currentValue, previousValue])

  return (
    <div>
      <h4 className='stat-title'>{StatTitle}</h4>
      <div className='countdown'>
        {!isOrdersReceived && (
          <PoundBlock size={sizePoundBlock} fontSize={fontSizePoundBlock}>
            £
          </PoundBlock>
        )}
        <div className='time-section' id={uniqueId}>
          <div className='time-group'></div>
        </div>
      </div>
    </div>
  )
}

const PoundBlock = styled.div`
  color: black;
  font-size: ${props => props.fontSize}px;
  font-weight: 900;
  background-color: white;
  width: ${props => props.size}px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: ${props => props.size}px !important;
`

export default DashboardStat
