import AntDIcon from 'antd/lib/icon'
import React from 'react'

const Icon = ({ size }) => {
  const SVG = () => (
    <svg
      width={size}
      height={size}
      fill='#000000'
      viewBox='0 0 36 36'
      version='1.1'
      preserveAspectRatio='xMidYMid meet'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <title>users-solid</title>
        <path
          className='clr-i-solid clr-i-solid-path-1'
          d='M12,16.14q-.43,0-.87,0a8.67,8.67,0,0,0-6.43,2.52l-.24.28v8.28H8.54v-4.7l.55-.62.25-.29a11,11,0,0,1,4.71-2.86A6.59,6.59,0,0,1,12,16.14Z'
        ></path>
        <path
          className='clr-i-solid clr-i-solid-path-2'
          d='M31.34,18.63a8.67,8.67,0,0,0-6.43-2.52,10.47,10.47,0,0,0-1.09.06,6.59,6.59,0,0,1-2,2.45,10.91,10.91,0,0,1,5,3l.25.28.54.62v4.71h3.94V18.91Z'
        ></path>
        <path
          className='clr-i-solid clr-i-solid-path-3'
          d='M11.1,14.19c.11,0,.2,0,.31,0a6.45,6.45,0,0,1,3.11-6.29,4.09,4.09,0,1,0-3.42,6.33Z'
        ></path>
        <path
          className='clr-i-solid clr-i-solid-path-4'
          d='M24.43,13.44a6.54,6.54,0,0,1,0,.69,4.09,4.09,0,0,0,.58.05h.19A4.09,4.09,0,1,0,21.47,8,6.53,6.53,0,0,1,24.43,13.44Z'
        ></path>
        <circle
          className='clr-i-solid clr-i-solid-path-5'
          cx='17.87'
          cy='13.45'
          r='4.47'
        ></circle>
        <path
          className='clr-i-solid clr-i-solid-path-6'
          d='M18.11,20.3A9.69,9.69,0,0,0,11,23l-.25.28v6.33a1.57,1.57,0,0,0,1.6,1.54H23.84a1.57,1.57,0,0,0,1.6-1.54V23.3L25.2,23A9.58,9.58,0,0,0,18.11,20.3Z'
        ></path>
        <rect x='0' y='0' width='36' height='36' fillOpacity='0'></rect>
      </g>
    </svg>
  )
  return <AntDIcon component={SVG} />
}
export default Icon
