import { PrinterOutlined } from '@ant-design/icons'
import { PDFExport } from '@progress/kendo-react-pdf'
import { Button, Menu, Modal, Popconfirm } from 'antd'
import Dinero from 'dinero.js'
import 'jspdf-autotable'
import moment from 'moment'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect, useLocation } from 'react-router-dom'
import Logo from 'src/assets/images/OMSLogoBlk.png'
import OMSErrorBoundary from 'src/components/shared/ErrorBoundary'
import HeaderScreen from 'src/components/shared/header-screen/HeaderScreen'
import getSubtitle from 'src/components/shared/header-screen/getSubtitle'
import getTitle from 'src/components/shared/header-screen/getTitle'
import NoDataScreen from 'src/components/shared/no-data-screen/NoDataScreen'
import FadeIn from 'src/components/styled/FadeIn'
import Loading from 'src/components/styled/Loading'
import Filter from 'src/screens/orders/Filter'
import styled from 'styled-components'
import _ from 'underscore'
import { postPhoneOrders } from '../../features/AddPhoneOrders'
import { fetchOrders } from '../../features/OrdersSlice'
import { fetchVenueProducts } from '../../features/VenueProductsSlice'
import { getVenueSuppliers } from '../../features/VenueSuppliersSlice'
import AddInvoiceModal from './add-invoice-modal/AddInvoiceModal'

const { Item } = Menu

const Orders = ({ children }) => {
  const [orderToInvoice, setOrderToInvoice] = useState({})
  const dispatch = useDispatch()
  const location = useLocation()
  const orderedProduct = useSelector(state => state.orders.orderedProduct)
  const [change, setChange] = useState('none')
  const [modalShow, setModalShow] = useState(false)
  const [addInvoiceLoading, setAddInvoiceLoading] = useState(false)
  const [isInvalidOrder, setIsInvalidOrder] = useState(false)
  const orderProductsGroupedByTypes = _(orderedProduct).groupBy(
    item => item.type,
  )
  const allOrdersProductsGroupedByType = orderProductsGroupedByTypes
  // const isCreditApproved = useSelector(
  //   state => state.auth.account.creditApproved,
  // )
  const isLoading = useSelector(state => state.orders.isLoading)
  const orders = useSelector(state => state.orders.filtered)
  const venue = useSelector(state => state.venue.venue)
  const venueSupplier = useSelector(
    state => state.venueSuppliers.venueSuppliers,
  )
  const venueProducts = useSelector(state => state.venueProducts.products)
  const orderProductsGroupedByType = _(orderedProduct).groupBy(
    // changed from type to supplier name to alter the CSV of ordered products
    item => item.supplierName,
  )
  useEffect(() => {
    dispatch(getVenueSuppliers(venueId))
    dispatch(fetchVenueProducts(venueId))
  }, [])

  const handleAddInvoice = async () => {
    setAddInvoiceLoading(true)
    await postPhoneOrders({ ...orderToInvoice, venue: venueId })
    setAddInvoiceLoading(false)
    setModalShow(false)
    const startDate = moment().subtract(40, 'days').format('DD/MM/YYYY')
    const endDate = moment().format('DD/MM/YYYY')
    await dispatch(fetchOrders(venueId, startDate, endDate))
  }

  const validateOrders = () => {
    if (!orderToInvoice.products || !orderToInvoice.products.length) {
      setIsInvalidOrder(true)
    } else {
      for (let i = 0; i < orderToInvoice.products.length; i++) {
        if (!orderToInvoice.products[i].product) {
          setIsInvalidOrder(true)
          break
        } else {
          setIsInvalidOrder(false)
        }
      }
    }
  }

  useEffect(() => {
    validateOrders()
  }, [JSON.stringify(orderToInvoice)])

  const array_of_obj = []
  Object.keys(orderProductsGroupedByType).forEach(typeName => {
    const dateOrders = orderProductsGroupedByType[typeName]
    array_of_obj.push(
      { product: typeName },
      {},
      {
        product: 'Product',
        quantity: 'Quantity',
        size: 'Pack size',
        price: 'Product price',
        type: 'Type',
        sku: 'Product Code',
        vatString: 'VAT',
        netString: 'Net',
        grossString: 'Gross',
        stock: 'Stock',
        value: 'Value',
        Wastage_quantity: 'Quantity of Wastage',
        Wastage_value: 'Value of Wastage',
      },
    )
    dateOrders.forEach(iterator => {
      array_of_obj.push({
        product: iterator?.product,
        quantity: iterator?.quantity,
        size: iterator?.size,
        price: iterator?.price,
        type: iterator?.type,
        sku: iterator?.sku,
        vatString: iterator?.vatString,
        netString: iterator?.netString,
        grossString: iterator?.grossString,
        stock: iterator?.stock,
        value: iterator?.value,
        Wastage_quantity: iterator?.Wastage_quantity,
        Wastage_value: iterator?.Wastage_value,
      })
    })
    array_of_obj.push({}, {})
  })

  const { _id: venueId, title } = venue || {}

  const pathFragments = location.pathname.split('/')
  const slug = pathFragments[pathFragments.length - 1]

  const headersOrderedProductCSV = [
    { key: 'product' },
    { key: 'quantity' },
    { key: 'size' },
    { key: 'price' },
    { key: 'type' },
    { key: 'sku' },
    { key: 'vatString' },
    { key: 'netString' },
    { key: 'grossString' },
    { key: 'stock' },
    { key: 'value' },
    { key: 'Wastage_quantity' },
    { key: 'Wastage_value' },
  ]

  const areSomeAcceptedOrder = orders.some(order => order.supplierConfirmed)

  const invoice = useRef()
  useEffect(() => {
    if (change === 'block') {
      invoice.current.save()
      setTimeout(() => {
        setChange('none')
      }, 120)
    }
  }, [change])

  let invoiceTotal = Dinero({
    amount: 0,
    currency: 'GBP',
  })

  let totalVat = Dinero({
    amount: 0,
    currency: 'GBP',
  })

  let totalExVat = Dinero({
    amount: 0,
    currency: 'GBP',
  })
  let total_Amount = 0
  let dummy_array = ['dummy data']
  const warningMessageAddToInvoice = (
    <pre>
      You are adding an order to your account <br />
      that was placed outside of Orders Made Simple.
      <br />
      The purpose of this is to update your records and <br />
      it WILL NOT send an order to your supplier
    </pre>
  )

  return slug === 'purchasing' ? (
    <Redirect to={`/v/${venueId}/purchasing`} />
  ) : (
    <Wrapper>
      <StyledMenu mode='horizontal' selectedKeys={[slug]}>
        <Item key='orders'>
          <Link to={`/v/${venueId}/purchasing/orders`}>Orders</Link>
        </Item>
        {venue?.isInvoices && (
          <Item key='gross-profit'>
            <Link to={`/v/${venueId}/purchasing/gross-profit`}>
              Gross Profit
            </Link>
          </Item>
        )}

        <Item key='invoices'>
          <Link to={`/v/${venueId}/purchasing/invoices`}>Invoices</Link>
        </Item>
        <Item key='receipting'>
          <Link to={`/v/${venueId}/purchasing/receipting`}>
            Goods receipting
          </Link>
        </Item>
        {venue.dataplusIntegration && (
          <Item key='dataplus'>
            <Link to={`/v/${venueId}/purchasing/dataplus`}>Dataplus</Link>
          </Item>
        )}
        <Item key='creditnotes'>
          <Link to={`/v/${venueId}/purchasing/creditnotes`}>Credit Notes</Link>
        </Item>
        <Item key='resolvednotes'>
          <Link to={`/v/${venueId}/purchasing/resolvednotes`}>
            Resolved Notes
          </Link>
        </Item>
        <Item key='statement'>
          <Link to={`/v/${venueId}/purchasing/statement`}>Overview</Link>
        </Item>
        {/* <Item key='basket'>
            <Link to={`/v/${venueId}/orders/basket`}>Basket</Link>
          </Item> */}
        <Item key='ordered'>
          <Link to={`/v/${venueId}/purchasing/ordered`}>Ordered products</Link>
        </Item>
        <Item key='spendTime'>
          <Link to={`/v/${venueId}/purchasing/spendTime`}>Spend over time</Link>
        </Item>
        {/*{(isCreditApproved || isAdmin) && (*/}
        {/*    <Item key='credit'>*/}
        {/*      <Link to={`/v/${venueId}/purchasing/credit`}>Credit</Link>*/}
        {/*    </Item>*/}
        {/*)}*/}
      </StyledMenu>

      <Content>
        <>
          <HeaderScreen
            title={getTitle(slug)}
            subTitle={getSubtitle(slug)}
            slug={slug}
            venueId={venueId}
          >
            {slug === 'orders' && !isLoading && (
              <Popconfirm
                placement={'topRight'}
                title={warningMessageAddToInvoice}
                onConfirm={() => setModalShow(true)}
                okText='Ok'
                cancelText='Cancel'
                style={{ width: '300px' }}
              >
                <Button style={{ background: '#b5d922' }} type={'primary'}>
                  Manually add order
                </Button>
              </Popconfirm>
            )}
            <Modal
              width={'60%'}
              title={'Add order'}
              okText={'Add to Invoice'}
              visible={modalShow}
              confirmLoading={addInvoiceLoading}
              okButtonProps={{ disabled: isInvalidOrder }}
              onOk={async () => {
                await handleAddInvoice()
              }}
              onCancel={() => {
                setModalShow(false)
              }}
            >
              {' '}
              <p>Please select supplier, then dates before adding products</p>
              <AddInvoiceModal
                showModal={modalShow}
                order={orderToInvoice}
                setOrder={setOrderToInvoice}
                suppliers={venueSupplier.filter(
                  supplier => supplier.isCatalogueAccessible,
                )}
                products={venueProducts}
              ></AddInvoiceModal>
            </Modal>

            {slug === 'ordered' && orderedProduct.length > 0 && (
              <div>
                <Button style={{ marginRight: '10px' }}>
                  <CSVLink
                    data={array_of_obj}
                    headers={headersOrderedProductCSV}
                    filename={`oms-products-export_${title}.csv`}
                  >
                    Download CSV
                  </CSVLink>
                </Button>
                <Button onClick={() => setChange('block')}>
                  <PrinterOutlined />
                </Button>
              </div>
            )}
          </HeaderScreen>
        </>
        <StyledLayout>
          {slug === 'credit' ||
          slug === 'basket' ||
          slug === 'receipting' ||
          slug === 'creditnotes' ||
          slug === 'resolvednotes' ||
          slug === 'invoices' ||
          slug === 'dataplus' ||
          slug === 'gross-profit' ||
          slug === 'xero' ? (
            <OMSErrorBoundary>{children}</OMSErrorBoundary>
          ) : (
            <Fragment>
              <Filter venueId={venueId} />
              <CenteredData>
                {isLoading ? (
                  <Loading />
                ) : (slug === 'orders' && orders.length === 0) ||
                  (slug !== 'orders' &&
                    slug !== 'receipting' &&
                    !areSomeAcceptedOrder) ? (
                  <NoDataScreen page={slug} />
                ) : (
                  <OMSErrorBoundary>{children}</OMSErrorBoundary>
                )}
              </CenteredData>
            </Fragment>
          )}
        </StyledLayout>
      </Content>
      <WrapperPdf style={{ display: change }}>
        <PDFExport ref={invoice} fileName='OMS Ordered'>
          <div className='invoice-box' ref={invoice}>
            <table cellPadding='0' cellSpacing='0'>
              <tbody>
                <tr className='top'>
                  <td colSpan='2'>
                    <table>
                      <tbody>
                        <tr>
                          <td className='title'>
                            <img src={Logo} alt='' />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr className='information'>
                  <td colSpan='12'>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            Orders Made Simple Limited
                            <br />
                            T/A Orders Made Simple
                            <br />
                            <br />
                            84 Commercial Street
                            <br />
                            Commercial Quay
                            <br />
                            Edinburgh
                            <br />
                            EH6 6LX
                            <br /> <br />
                            SC633011
                            <br />
                            VAT Number: 347 8756 47
                          </td>

                          <td>
                            {venue.title}
                            <br />
                            {venue.addressLine1}
                            <br />
                            {venue.addressLine2}
                            <br />
                            {venue.addressCity}
                            <br />
                            {venue.addressPostCode}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td colSpan='5'>&nbsp;</td>
                </tr>

                {Object.keys(allOrdersProductsGroupedByType).map(typeName => {
                  const dateOrders = allOrdersProductsGroupedByType[typeName]
                  let groupTotal = Dinero({
                    amount: 0,
                    currency: 'GBP',
                  })

                  let groupTotalVat = Dinero({
                    amount: 0,
                    currency: 'GBP',
                  })

                  let totalPrice = 0
                  dateOrders.forEach(order => {
                    const {
                      price,
                      updatedQuantity,
                      quantity,
                      hasVat,
                      grossNumber,
                    } = order
                    totalPrice += Number(grossNumber)
                    const productPrice = Dinero({
                      amount: price && !isNaN(price) ? parseInt(price) : 0,
                      currency: 'GBP',
                    })
                    // const grossPrice = Dinero({
                    //   amount: Number(grossNumber),
                    //   currency: 'GBP',
                    // })

                    const vatAmount = hasVat
                      ? productPrice
                          .multiply(updatedQuantity || quantity)
                          .percentage(20)
                      : Dinero({
                          amount: 0,
                          currency: 'GBP',
                        })

                    // const lineTotal = productPrice
                    //   .multiply(updatedQuantity || quantity)
                    //   .add(vatAmount)

                    groupTotalVat = groupTotalVat.add(vatAmount)
                  })

                  groupTotal = groupTotal.add(
                    Dinero({
                      amount: !isNaN(parseInt(totalPrice))
                        ? parseInt(totalPrice)
                        : 0,
                      currency: 'GBP',
                    }),
                  )

                  return (
                    <React.Fragment key={typeName}>
                      <tr>
                        <td className='typeHeader' rowSpan='2'>
                          {typeName}
                        </td>
                        <td colSpan='11'></td>
                        <td>
                          <b>Total</b>{' '}
                          {groupTotal.multiply(100).toFormat('$0,0.00')}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan='11'></td>
                        <td>
                          <b>Total VAT</b>{' '}
                          {groupTotalVat.multiply(100).toFormat('$0,0.00')}
                        </td>
                      </tr>

                      <tr className='heading'>
                        <td>Product</td>
                        <td>Qty</td>
                        <td>Size</td>
                        <td>Price</td>
                        <td>Type</td>
                        <td>SKU</td>
                        <td>VAT</td>
                        <td>Net</td>
                        <td>Gross</td>
                        <td>In Stock</td>
                        <td>value of stock</td>
                        <td>Quantity of Wastage</td>
                        <td>Value of Wastage</td>
                      </tr>

                      {dateOrders.map(order => {
                        const {
                          price,
                          updatedQuantity,
                          quantity,
                          hasVat,
                          grossNumber,
                        } = order
                        const productPrice = Dinero({
                          amount: !isNaN(parseInt(price)) ? parseInt(price) : 0,
                          currency: 'GBP',
                        })

                        total_Amount += Number(grossNumber)
                        let vatAmount = hasVat
                          ? productPrice
                              .multiply(updatedQuantity || quantity)
                              .percentage(20)
                          : Dinero({
                              amount: 0,
                              currency: 'GBP',
                            })

                        // let lineTotal = productPrice
                        //   .multiply(updatedQuantity || quantity)
                        //   .add(vatAmount)

                        totalVat = totalVat.add(vatAmount)

                        // invoiceTotal = invoiceTotal.add(lineTotal)

                        totalExVat = totalExVat.add(
                          productPrice.multiply(updatedQuantity || quantity),
                        )

                        return (
                          orderedProduct && (
                            <tr key={order.key} className='item'>
                              <td>{order.product}</td>
                              <td>{quantity}</td>
                              <td>{order.size}</td>
                              <td>{`£${Number(price).toFixed(2) || 0}`}</td>
                              <td>{order.type}</td>
                              <td>{order.sku}</td>
                              <td>
                                {order.vatString}
                                {/* {ordervatString
                              ? `${hasVat.toFormat('$0,0.00')}`
                              : '-'} */}
                              </td>
                              <td>{order.netString}</td>
                              <td>{order.grossString}</td>
                              <td>{order.stock}</td>
                              <td>{order.value}</td>
                              <td>{order.Wastage_quantity}</td>
                              <td>{order.Wastage_value}</td>
                            </tr>
                          )
                        )
                      })}

                      <tr>
                        <td colSpan='5'>&nbsp;</td>
                      </tr>
                      <tr>
                        <td colSpan='5'>&nbsp;</td>
                      </tr>
                    </React.Fragment>
                  )
                })}
                {dummy_array.map(() => {
                  invoiceTotal = invoiceTotal.add(
                    Dinero({
                      amount: !isNaN(parseInt(total_Amount))
                        ? parseInt(total_Amount)
                        : 0,
                      currency: 'GBP',
                    }),
                  )
                })}

                <tr className='total'>
                  <td colSpan='11'></td>

                  <td>Total excl VAT:</td>
                  <td>{totalExVat.multiply(100).toFormat('$0,0.00')}</td>
                  <td colSpan='11'></td>
                </tr>

                <tr className='total'>
                  <td colSpan='11'></td>

                  <td>Total VAT:</td>
                  <td>{totalVat.multiply(100).toFormat('$0,0.00')}</td>
                  <td colSpan='11'></td>
                </tr>

                <tr className='total'>
                  <td colSpan='11'></td>

                  <td>Total:</td>
                  <td>{invoiceTotal.multiply(100).toFormat('$0,0.00')}</td>
                  <td colSpan='11'></td>
                </tr>
              </tbody>
            </table>
          </div>
        </PDFExport>
      </WrapperPdf>
    </Wrapper>
  )
}

const Wrapper = styled(FadeIn)``
const WrapperPdf = styled.div`
  .invoice-box {
    max-width: 1800px;
    margin: auto;
    padding: 30px;
    border: 1px solid ${({ theme }) => theme.colours.bgGrey};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    color: ${({ theme }) => theme.colours.oliveGrey};
  }
  .invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
  }

  .invoice-box table td {
    padding: 5px;
    vertical-align: top;
  }

  .invoice-box table td.typeHeader {
    font-size: 26px;
    font-weight: bold;
  }

  .invoice-box table tr td:nth-child(2) {
    text-align: left;
  }

  .invoice-box table tr.top table td {
    padding-bottom: 20px;
  }

  .invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: ${({ theme }) => theme.colours.bbqBlack};
  }

  .invoice-box table tr.information table td {
    padding-bottom: 40px;
  }

  .invoice-box table tr.heading td {
    background: ${({ theme }) => theme.colours.mayoWhite};
    border-bottom: 1px solid ${({ theme }) => theme.colours.bgGrey};
    font-weight: bold;
  }

  .invoice-box table tr.details td {
    padding-bottom: 20px;
  }

  .invoice-box table tr.item td {
    border-bottom: 1px solid ${({ theme }) => theme.colours.mayoWhite};
  }

  .invoice-box table tr.item.last td {
    border-bottom: none;
  }

  .invoice-box table tr.total td:nth-child(2),
  .invoice-box table tr.total td:nth-child(3) {
    border-top: 2px solid ${({ theme }) => theme.colours.mayoWhite};
    font-weight: bold;
  }

  @media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
      width: 100%;
      display: block;
      text-align: center;
    }

    .invoice-box table tr.information table td {
      width: 100%;
      display: block;
      text-align: center;
    }
  }

  /** RTL **/
  .rtl {
    direction: rtl;
    font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial,
      sans-serif;
  }

  .rtl table {
    text-align: right;
  }

  .rtl table tr td:nth-child(2) {
    text-align: left;
  }
`

const Content = styled(FadeIn)`
  margin: ${({ theme }) => theme.spacing.lg} ${({ theme }) => theme.spacing.xxl};
`

const CenteredData = styled.div`
  width: calc(100% - 300px);
`

const StyledMenu = styled(Menu)`
  background: ${({ theme }) => theme.colours.mayoWhite};
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  a:active,
  a:hover {
    color: ${({ theme }) => theme.colours.bbqBlack} !important;
    border-bottom: none !important;
  }
`

const StyledLayout = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`

export default Orders
