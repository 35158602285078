import { Input, Select, Form } from 'antd'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'
import { API } from 'aws-amplify'
import DeliveryDaysSelect from 'src/components/DeliveryDaysField'

const CustomerCard = ({
  addingCustomer,
  setAddingCustomer,
  getFieldDecorator,
}) => {
  const account = useSelector(state => state.auth.account)
  const supplierId = account?.supplier[0]._id
  const [suppliers, setSuppliers] = useState(null)

  useEffect(() => {
    const fetchSupplier = async () => {
      try {
        const result = await API.get('api', `suppliers/${supplierId}`)
        setSuppliers(result)
        console.log(result)
      } catch (error) {
        console.error('Error loading customer:', error)
      }
    }

    fetchSupplier()
  }, [supplierId])

  const handleSupplierChange = value => {
    setAddingCustomer(prev => ({
      ...prev,
      supplierid: value,
    }))
  }
  return (
    <Form>
      <CardComp>
        <FormItem>
          {getFieldDecorator('emailOfCustomer', {
            rules: [
              {
                required: true,
                message: 'Please input email of customer',
              },
              {
                type: 'email',
                message: 'The input is not valid email',
              },
            ],
          })(
            <InputComp
              value={addingCustomer.emailOfCustomer}
              addonBefore={'Email of Customer'}
              onChange={e => {
                setAddingCustomer({
                  ...addingCustomer,
                  emailOfCustomer: e.target.value,
                })
              }}
              placeholder='Email of Customer'
            />,
          )}
        </FormItem>

        <FormItem>
          {getFieldDecorator('accountName', {
            rules: [
              {
                required: true,
                message: 'Please input account name',
              },
            ],
          })(
            <InputComp
              value={addingCustomer.accountName}
              addonBefore={'Account Name'}
              onChange={e => {
                setAddingCustomer({
                  ...addingCustomer,
                  accountName: e.target.value,
                })
              }}
              placeholder='Account Name'
            />,
          )}
        </FormItem>

        <FormItem>
          {getFieldDecorator('venueName', {
            rules: [
              {
                required: true,
                message: 'Please input venue name',
              },
            ],
          })(
            <InputComp
              value={addingCustomer.venueName}
              addonBefore='Venue Name'
              onChange={e => {
                setAddingCustomer({
                  ...addingCustomer,
                  venueName: e.target.value,
                })
              }}
              placeholder='Venue Name'
            />,
          )}
        </FormItem>

        <FormItem>
          {getFieldDecorator('accountNumber', {
            rules: [
              {
                required: true,
                message: 'Please input account number',
              },
              {
                pattern: /^\d+$/,
                message: 'The account number must be a positive number!',
              },
            ],
          })(
            <InputComp
              value={addingCustomer.accountNumber}
              addonBefore='Account Number'
              onChange={e => {
                setAddingCustomer({
                  ...addingCustomer,
                  accountNumber: e.target.value,
                })
              }}
              placeholder='Account Number'
            />,
          )}
        </FormItem>

        <FormItem>
          {getFieldDecorator('addressCity', {
            rules: [
              {
                required: true,
                message: 'Please input address city',
              },
            ],
          })(
            <InputComp
              value={addingCustomer.addressCity}
              addonBefore='Address City'
              onChange={e => {
                setAddingCustomer({
                  ...addingCustomer,
                  addressCity: e.target.value,
                })
              }}
              placeholder='Address City'
            />,
          )}
        </FormItem>

        <FormItem>
          {getFieldDecorator('addressLine1', {
            rules: [
              {
                required: true,
                message: 'Please input address line 1',
              },
            ],
          })(
            <InputComp
              value={addingCustomer.addressLine1}
              addonBefore='Address Line 1'
              onChange={e => {
                setAddingCustomer({
                  ...addingCustomer,
                  addressLine1: e.target.value,
                })
              }}
              placeholder='Address Line 1'
            />,
          )}
        </FormItem>

        <FormItem>
          {getFieldDecorator('addressLine2', {
            rules: [
              {
                required: true,
                message: 'Please input address line 2',
              },
            ],
          })(
            <InputComp
              value={addingCustomer.addressLine2}
              addonBefore='Address Line 2'
              onChange={e => {
                setAddingCustomer({
                  ...addingCustomer,
                  addressLine2: e.target.value,
                })
              }}
              placeholder='Address Line 2'
            />,
          )}
        </FormItem>

        <FormItem>
          {getFieldDecorator('addressPostCode', {
            rules: [
              {
                required: true,
                message: 'Please input address post code',
              },
            ],
          })(
            <InputComp
              value={addingCustomer.addressPostCode}
              addonBefore='Address Post Code'
              onChange={e => {
                setAddingCustomer({
                  ...addingCustomer,
                  addressPostCode: e.target.value,
                })
              }}
              placeholder='Address Post Code'
            />,
          )}
        </FormItem>

        <FormItem>
          {getFieldDecorator('minimumOrder', {
            rules: [
              {
                required: true,
                message: 'Please input minimum order',
              },
              {
                pattern: /^\d+$/,
                message: 'The minimum order must be a positive number!',
              },
            ],
          })(
            <InputComp
              value={addingCustomer.minimumOrder}
              addonBefore='Minimum Order'
              onChange={e => {
                setAddingCustomer({
                  ...addingCustomer,
                  minimumOrder: e.target.value,
                })
              }}
              placeholder='Minimum Order'
            />,
          )}
        </FormItem>

        <FormItem>
          {getFieldDecorator('deliveryFee', {
            rules: [
              {
                required: true,
                message: 'Please input delivery fee',
              },
              {
                pattern: /^\d+$/,
                message: 'The delivery fee must be a positive number!',
              },
            ],
          })(
            <InputComp
              value={addingCustomer.deliveryFee}
              addonBefore='Delivery Fee'
              onChange={e => {
                setAddingCustomer({
                  ...addingCustomer,
                  deliveryFee: e.target.value,
                })
              }}
              placeholder='Delivery Fee'
            />,
          )}
        </FormItem>

        <FormItem>
          {getFieldDecorator('deliveryDays', {
            rules: [
              {
                required: true,
                message: 'Please select the delivery days',
              },
            ],
          })(
            <DeliveryDaysSelect
              deliveryDays={addingCustomer.deliveryDays}
              onChange={value => {
                setAddingCustomer({ ...addingCustomer, deliveryDays: value })
              }}
              isDisabled={false}
            />,
          )}
        </FormItem>

        <FormItem>
          {getFieldDecorator('orderCutOffTime', {
            rules: [
              {
                required: true,
                message: 'Please input the order cut off time',
              },
            ],
          })(
            <InputComp
              value={addingCustomer.orderCutOffTime}
              onChange={e => {
                setAddingCustomer({
                  ...addingCustomer,
                  orderCutOffTime: e.target.value,
                })
              }}
              addonBefore={'Order cut off time'}
              placeholder='Order cut off time'
            />,
          )}
        </FormItem>

        <FormItem>
          <StyledDiv>
            <span>Price File</span>
            <Select
              allowClear
              placeholder='Price File'
              style={{ width: '81%' }}
              onChange={handleSupplierChange}
            >
              {suppliers && (
                <Select.Option key={suppliers._id} value={suppliers._id}>
                  {`${suppliers.title} ${suppliers.secondTitle}`}
                </Select.Option>
              )}
            </Select>
          </StyledDiv>
        </FormItem>
      </CardComp>
    </Form>
  )
}

const CardComp = styled.div`
  width: 470px;
`

const InputComp = styled(Input)`
  // margin-bottom: 12px;
`

const StyledDiv = styled.div`
  margin-bottom: 12px;
  span {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 4px 10px;
    max-height: 32px;
    border: 1px solid ${({ theme }) => theme.colours.panSilver};
    background-color: ${({ theme }) => theme.colours.bgGrey};
  }
  display: flex;
`

const FormItem = styled(Form.Item)`
  margin-bottom: 12px !important;
`

export default CustomerCard
