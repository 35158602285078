import { API } from 'aws-amplify'
import Sentry from 'src/utils/sentry'

export const getLogoSupplier = async supplierId => {
  try {
    const supplier = await API.get('api2', `suppliers/${supplierId}`)
    return supplier.logoUrl
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}
