import AntDIcon from 'antd/lib/icon'
import React from 'react'

const Icon = ({ size }) => {
  const SVG = () => (
    <svg
      width={size}
      height={size}
      fill='#000000'
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
    >
      <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        {' '}
        <g>
          {' '}
          <g>
            {' '}
            <g>
              {' '}
              <path d='M493.62,128.949c-5.423-9.902-10.547-19.255-14.103-32.225c-1.351-4.929-6.44-7.827-11.37-6.477 c-4.929,1.351-7.828,6.441-6.477,11.37c4.129,15.061,10.022,25.82,15.719,36.222c8.642,15.777,16.105,29.404,16.105,56.474 v299.181H240.579V194.313c0-27.071,7.463-40.696,16.105-56.474c9.035-16.494,19.18-35.017,20.709-69.984h188.342h12.337 c5.11,0,9.253-4.143,9.253-9.253V9.253c0-5.11-4.143-9.253-9.253-9.253H256c-5.11,0-9.253,4.143-9.253,9.253v49.349 c0,5.11,4.143,9.253,9.253,9.253h2.871c-1.408,30.024-9.691,45.16-18.42,61.095c-9.035,16.497-18.379,33.556-18.379,65.363 v308.434c0,5.11,4.143,9.253,9.253,9.253h271.422c5.11,0,9.253-4.143,9.253-9.253V194.313 C512,162.507,502.656,145.448,493.62,128.949z M265.253,18.506H468.82V49.35H265.253V18.506z'></path>
              <path d='M453.398,364.561c5.11,0,9.253-4.143,9.253-9.253v-136.32c0-5.11-4.143-9.253-9.253-9.253H280.675 c-5.11,0-9.253,4.143-9.253,9.253v172.723c0,5.11,4.143,9.253,9.253,9.253h172.723c5.11,0,9.253-4.143,9.253-9.253 c0-5.11-4.143-9.253-9.253-9.253h-163.47V228.241h154.217v127.067C444.145,360.419,448.288,364.561,453.398,364.561z'></path>
              <path d='M181.976,259.085H9.253c-2.539,0-4.966,1.042-6.713,2.884c-1.747,1.842-2.661,4.319-2.528,6.855l12.337,234.41 c0.259,4.914,4.319,8.767,9.241,8.767h148.048c4.921,0,8.982-3.853,9.241-8.767l12.337-234.41 c0.133-2.535-0.781-5.013-2.528-6.855C186.942,260.127,184.515,259.085,181.976,259.085z M160.86,493.494H30.37l-8.117-154.217 h110.374c5.11,0,9.253-4.143,9.253-9.253s-4.143-9.253-9.253-9.253H21.591c-0.105,0-0.207,0.012-0.311,0.016l-2.274-43.197 h153.216L160.86,493.494z'></path>
              <path d='M387.762,351.495c4.476,0.724,8.825-1.901,10.26-6.208l3.559-10.678l3.559,10.678c1.276,3.826,4.849,6.328,8.772,6.328 c0.491,0,0.99-0.039,1.488-0.12c4.48-0.727,7.771-4.596,7.771-9.133v-49.349c0-11.904-9.686-21.59-21.59-21.59h-49.219 l-4.207-4.207c-5.243-5.243-12.214-8.13-19.629-8.13h-0.97c-3.743,0-7.116,2.254-8.549,5.712 c-1.432,3.458-0.64,7.437,2.006,10.083l5.172,5.173l-14.424,12.019c-1.997,1.664-3.203,4.092-3.32,6.689 c-0.118,2.597,0.862,5.124,2.701,6.962l6.169,6.169c1.735,1.736,4.089,2.711,6.543,2.711h6.786v27.759 c0,4.538,3.29,8.407,7.771,9.133c4.476,0.724,8.825-1.901,10.26-6.208l3.559-10.678l3.559,10.678 c1.435,4.306,5.776,6.929,10.26,6.208c4.48-0.727,7.771-4.596,7.771-9.133V326.94h6.169v15.422 C379.991,346.899,383.282,350.768,387.762,351.495z'></path>{' '}
            </g>{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  )
  return <AntDIcon component={SVG} />
}
export default Icon
