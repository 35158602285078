import Sentry from 'src/utils/sentry'
import { createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'

const analyticsSearchSlice = createSlice({
  name: 'analyticsSearch',
  initialState: {
    customers: [],
    title: '',
    total: 0,
    loading: false,
    error: '',
  },
  reducers: {
    setTitle: (state, action) => {
      const title = action.payload
      state.title = title
      if (!title.trim()) {
        state.customers = []
      }
    },
    setCustomerAnalytics: (state, action) => {
      state.customers = action.payload
    },

    setSearchLoading: (state, action) => {
      state.loading = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setTotal: (state, action) => {
      state.total = action.payload
    },
  },
})

export const {
  setTitle,
  setTotal,
  setCustomerAnalytics,
  setSearchLoading,
  setError,
} = analyticsSearchSlice.actions

export const performAnalyticsSearch =
  (supplierId, venueTitle) => async dispatch => {
    try {
      dispatch(setSearchLoading(true))
      const resultSearch = await API.get(
        'api2',
        `suppliers/${supplierId}/venueOrders?venueTitle=${venueTitle}`,
      )

      const analyticsList = resultSearch.customer
      const count = analyticsList.length
      dispatch(setCustomerAnalytics(analyticsList))
      dispatch(setTotal(count))
      dispatch(setSearchLoading(false))
    } catch (e) {
      console.error('Error fetching all customers:', e)
      Sentry.captureException(e)
      return false
    }
  }

export default analyticsSearchSlice
