import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Collapse, Modal, Spin, Table } from 'antd'
import {
  addProduct,
  createNewReceipting,
  fetchReceiptingProducts,
  getHistory,
  setReceiptingOrders,
  updateReceiptingOrder,
} from '../../features/ReceiptingSlice'
import { Center } from '../../components/styled/FlexBox'
import OrdersColumns from './OrdersColumns'
import ProductColumns from './ProductColumns'
import ReceiptingFilter from './ReceiptingFilter'
import styled from 'styled-components/macro'
import { Prompt } from 'react-router-dom'
import AddProductModal from './addProduct/AddProductModal'
import { getTotalSpendReceipting } from '../../utils/priceFunctions'
import moment from 'moment'
import { findOrderAndUpdateState } from '../../features/OrdersSlice'
const { Panel } = Collapse

const Receipting = ({ venueId }) => {
  const dispatch = useDispatch()

  const venue = useSelector(state => state.venue.venue)

  const receiptingOrders = useSelector(
    state => state.receipting.receiptingOrders,
  )

  const receiptingProducts = useSelector(
    state => state.receipting.receiptingProducts,
  )
  const receiptingLoading = useSelector(
    state => state.receipting.receiptingLoading,
  )
  const isLoading = useSelector(state => state.receipting.isLoading)
  const receiptingHistory = useSelector(state => state.receipting.history)
  const historyLoading = useSelector(state => state.receipting.historyLoading)
  const user = useSelector(state => state.auth.account.name)
  const createdReceipting = useSelector(
    state => state.receipting.createdReceipting,
  )

  const [editedReceipting, setEditedReceipting] = useState([])
  const [weightedItems, setWeightedItems] = useState([])
  const [ordersToAdding, setOrdersToAdding] = useState({})
  const [modalShow, setModalShow] = useState(false)
  const [historyModalShow, setHistoryModalShow] = useState(false)
  const [isSupplierChecked, setIsSupplierChecked] = useState(false)
  const [isPoChecked, setIsPoChecked] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [isEmptySearch, setIsEmptySearch] = useState(false)
  const [filteredOrders, setFilteredOrders] = useState([])

  //filter posts with input and checkboxes
  useEffect(() => {
    setFilteredOrders(
      receiptingOrders.filter(item => {
        if (isPoChecked) {
          return item.purchaseOrderNumber?.toLowerCase()?.includes(searchQuery)
        } else if (isSupplierChecked) {
          return item.supplier.title
            ?.trim()
            ?.toLowerCase()
            ?.includes(searchQuery)
        } else return true
      }),
    )
    if (!searchQuery) setFilteredOrders(receiptingOrders)
  }, [receiptingOrders, searchQuery, isPoChecked, isSupplierChecked])

  const [totalRecordPrice, setTotalRecordPrice] = useState(0)

  useEffect(() => {
    const updatedOrders = receiptingOrders.map(order => {
      const currentOrder = receiptingProducts.find(
        item =>
          item.supplier === order.supplier._id &&
          item.purchaseOrderNumber === order.purchaseOrderNumber,
      )
      return {
        ...order,
        totalSpend: currentOrder
          ? getTotalSpendReceipting(currentOrder.orders)
          : order.totalSpend,
        totalSpendReceived: currentOrder
          ? getTotalSpendReceipting(currentOrder.orders)
          : order.totalSpendReceived,
      }
    })
    dispatch(setReceiptingOrders(updatedOrders))
  }, [receiptingProducts])

  const isEditable = (order, field) => {
    const currentOrder = editedReceipting.find(item => item.order === order._id)
    switch (field) {
      case 'weight': {
        return true
      }
      case 'price': {
        return !!currentOrder
      }
      default: {
        return !!currentOrder
      }
    }
  }

  const onFullAccept = record => {
    const supplierId = record.supplier._id
    const PONumber = record.purchaseOrderNumber
    const data = receiptingProducts
      .find(
        item =>
          item.supplier._id === supplierId &&
          item.purchaseOrderNumber === PONumber,
      )
      .orders.map(item => {
        return {
          order: item._id,
          supplier: item.supplier,
          purchaseOrderNumber: item.purchaseOrderNumber,
          status: 'Full accept',
          placedVia: user,
        }
      })
    const updateInfo = {
      supplierId,
      PONumber,
      venueId,
      savedBy: user,
      receiptingStatus: 'Full accept',
    }
    dispatch(createNewReceipting(data, updateInfo))
  }

  const onSave = record => {
    const supplierId = record.supplier._id
    const PONumber = record.purchaseOrderNumber
    const updateInfo = {
      supplierId,
      PONumber,
      venueId,
      savedBy: user,
    }
    let data = []
    if (!weightedItems.length) {
      data = editedReceipting
        .filter(item => {
          return (
            item.supplier === supplierId &&
            item.purchaseOrderNumber === PONumber
          )
        })
        .map(item => {
          return { ...item, placedVia: user }
        })
      updateInfo.receiptingStatus = data.some(
        item =>
          item.error !== 'Weighted item' ||
          item.error !== 'Supplier / Invoice adjustment',
      )
        ? 'Sent to supplier'
        : 'Credit approved by supplier'
    } else if (!editedReceipting.length) {
      data = weightedItems
        .filter(item => {
          return (
            item.supplier === supplierId &&
            item.purchaseOrderNumber === PONumber
          )
        })
        .map(item => {
          return { ...item, placedVia: user }
        })
      updateInfo.receiptingStatus = 'Credit approved by supplier'
    } else {
      const mergedArray = editedReceipting.map(item => {
        const weightedItem = weightedItems.find(
          weightedItem => weightedItem.order === item.order,
        )
        const indexWeightedItem = weightedItems.indexOf(weightedItem)
        if (weightedItem) {
          weightedItems.splice(indexWeightedItem, 1)
          return {
            ...item,
            weight: weightedItem.weight,
            orderedQuantity: weightedItem.orderedQuantity,
          }
        } else {
          return item
        }
      })
      data = [...mergedArray, ...weightedItems]
        .filter(item => {
          return (
            item.supplier === supplierId &&
            item.purchaseOrderNumber === PONumber
          )
        })
        .map(item => {
          return { ...item, placedVia: user }
        })
      updateInfo.receiptingStatus = 'Sent to supplier'
    }
    dispatch(createNewReceipting(data, updateInfo))
    updateInfo.totalSpend = getTotalSpendReceipting(
      receiptingProducts.find(
        item =>
          item.supplier === supplierId && item.purchaseOrderNumber === PONumber,
      ).orders,
    )
    dispatch(updateReceiptingOrder(updateInfo))

    setEditedReceipting(
      editedReceipting.filter(item => {
        return (
          item.supplier !== supplierId && item.purchaseOrderNumber !== PONumber
        )
      }),
    )
    setWeightedItems(
      weightedItems.filter(item => {
        return (
          item.supplier !== supplierId && item.purchaseOrderNumber !== PONumber
        )
      }),
    )
    const search = receiptingOrders.map(order => {
      if (
        supplierId === order.supplier._id &&
        PONumber === order.purchaseOrderNumber
      ) {
        const product = receiptingProducts.find(
          item =>
            item.supplier === supplierId &&
            item.purchaseOrderNumber === PONumber,
        ).orders

        let newPrice = 0
        if (weightedItems && weightedItems[0] && weightedItems[0]?.weight) {
          newPrice = weightedItems[0]?.weight * product[0].price
        } else if (editedReceipting && editedReceipting.length > 0) {
          const editedReceipt = editedReceipting.filter(
            order =>
              order.purchaseOrderNumber === PONumber &&
              order.supplier === supplierId,
          )

          newPrice = editedReceipt[0]?.updatedQuantity * product[0].price
        } else if (
          product[0]?.receipting &&
          product[0].receipting?.updatedQuantity
        ) {
          newPrice = product[0].price * product[0].receipting?.updatedQuantity
        } else {
          newPrice =
            product[0].price * product[0].quantity || record.totalSpendReceived
        }
        const updatedOrder = { ...order, totalSpendReceived: newPrice }
        return updatedOrder
      } else {
        return order
      }
    })
    dispatch(setReceiptingOrders(search))
  }

  useEffect(() => {
    if (createdReceipting.length) {
      dispatch(findOrderAndUpdateState(createdReceipting))
    }
  }, [createdReceipting])
  const getExpandedOrder = record => {
    const supplierId = record.supplier._id
    const PONumber = record.purchaseOrderNumber
    const currentOrder = receiptingProducts.find(
      item =>
        item.supplier._id === supplierId &&
        item.purchaseOrderNumber === PONumber,
    )
    if (currentOrder) return
    dispatch(fetchReceiptingProducts(venueId, supplierId, PONumber))
  }
  const renderExpandedOrder = record => {
    const supplierId = record.supplier._id
    const PONumber = record.purchaseOrderNumber
    const currentOrder = receiptingProducts.find(
      item =>
        item.supplier._id === supplierId &&
        item.purchaseOrderNumber === PONumber,
    )
    return receiptingLoading && !currentOrder ? (
      <Center>
        <Spin size={'large'} />
      </Center>
    ) : (
      <Table
        columns={ProductColumns(
          editedReceipting,
          setEditedReceipting,
          isEditable,
          weightedItems,
          setWeightedItems,
          openHistory,
          totalRecordPrice,
          setTotalRecordPrice,
        )}
        dataSource={currentOrder?.orders}
        pagination={false}
        rowKey={record => record._id}
      />
    )
  }

  const openHistory = orderId => {
    setHistoryModalShow(true)
    dispatch(getHistory(orderId))
  }

  const parseHistoryInfo = (item, index, array) => {
    const date = moment(item.createdAt).format('DD-MM-YYYY')
    const time = moment(item.createdAt).format('HH:mm')
    const info = []
    for (const key in item) {
      switch (key) {
        case 'updatedQuantity': {
          if (!index) {
            info.push(
              `Change quantity from ${
                item.order?.orderedQuantity || item.order?.quantity
              } to ${item[key]}`,
            )
          } else {
            info.push(
              `Change quantity from ${
                array[index - 1].updatedQuantity || item.orderedQuantity
              } to ${item[key]}`,
            )
          }
          break
        }
        case 'updatedPrice': {
          if (!index) {
            info.push(
              `Change price from ${
                item.order?.orderedPrice || item.order?.price
              } to ${item[key]}`,
            )
          } else {
            info.push(
              `Change price from ${(
                (array[index - 1].updatedPrice || item.orderedPrice) / 100
              ).toFixed(2)} to ${(item[key] / 100).toFixed(2)}`,
            )
          }
          break
        }
        case 'updatedSku': {
          if (!index) {
            info.push(
              `Change sku from ${
                item.order?.orderedSku || item.order?.sku
              } to ${item[key]}`,
            )
          } else {
            info.push(
              `Change sku from ${
                array[index - 1].updatedSku || item.orderedSku
              } to ${item[key]}`,
            )
          }
          break
        }
        case 'updatedSize': {
          if (!index) {
            info.push(
              `Change size from ${
                item.order?.orderedSize || item.order?.size
              } to ${item[key]}`,
            )
          } else {
            info.push(
              `Change size from ${
                array[index - 1].updatedSize || item.orderedSize
              } to ${item[key]}`,
            )
          }
          break
        }
        case 'updatedTitle': {
          if (!index) {
            info.push(
              `Change title from ${
                item.order?.orderedTitle || item.order?.title
              } to ${item[key]}`,
            )
          } else {
            info.push(
              `Change title from ${
                array[index - 1].updatedTitle || item.orderedTitle
              } to ${item[key]}`,
            )
          }
          break
        }
      }
    }

    return (
      <Panel
        key={item._id}
        header={
          item.error
            ? `${item.placedVia} reported "${item.error}" on ${date} ${time}`
            : `${item.placedVia} accepted on ${date} ${time}`
        }
      >
        {info.map(item => (
          <p key={item._id + index}>{item}</p>
        ))}
      </Panel>
    )
  }

  return (
    <>
      <Prompt
        when={!!(editedReceipting.length || weightedItems.length)}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      <div style={{ flex: '0 0 100%' }}>
        <HeaderTable>
          <ReceiptingFilter
            venueId={venueId}
            receiptingOrders={receiptingOrders}
            isSupplierChecked={isSupplierChecked}
            setIsSupplierChecked={setIsSupplierChecked}
            isPoChecked={isPoChecked}
            setIsPoChecked={setIsPoChecked}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            setIsEmptySearch={setIsEmptySearch}
            isEmptySearch={isEmptySearch}
          />
          {/*<Link to={`/v/${venueId}/receipting/history/`}>*/}
          {/*  <Button>*/}
          {/*    History*/}
          {/*  </Button>*/}
          {/*</Link>*/}
        </HeaderTable>
        <StyledTable
          columns={OrdersColumns(
            editedReceipting,
            weightedItems,
            onSave,
            onFullAccept,
            receiptingProducts,
            setOrdersToAdding,
            venueId,
            setModalShow,
            venue.dataplusIntegration,
          )}
          dataSource={filteredOrders}
          expandedRowRender={renderExpandedOrder}
          onExpand={(expanded, record) =>
            expanded ? getExpandedOrder(record) : null
          }
          rowKey={record =>
            `${record.purchaseOrderNumber}${record.supplier._id}`
          }
          bordered
        />
      </div>
      <Modal
        width={'60%'}
        title={'Add product to order'}
        okText={'Add product'}
        visible={modalShow}
        okButtonProps={{
          disabled:
            ordersToAdding.quantity === '' ||
            ordersToAdding.quantity === undefined ||
            ordersToAdding.quantity === '0',
        }}
        onCancel={() => {
          setOrdersToAdding({})
          setModalShow(false)
        }}
        onOk={() => {
          dispatch(addProduct(ordersToAdding))
          setModalShow(false)
        }}
        confirmLoading={isLoading}
      >
        <AddProductModal
          ordersToAdding={ordersToAdding}
          setOrdersToAdding={setOrdersToAdding}
        />
      </Modal>
      <Modal
        width={'50%'}
        title={'Order changes history'}
        footer={null}
        visible={historyModalShow}
        onCancel={() => setHistoryModalShow(false)}
      >
        {historyLoading ? (
          <Center>
            <Spin size={'large'} />
          </Center>
        ) : (
          <Collapse>
            {receiptingHistory.map((item, index, array) =>
              parseHistoryInfo(item, index, array),
            )}
          </Collapse>
        )}
      </Modal>
    </>
  )
}

const StyledTable = styled(Table)`
  .restrict-shrink {
    min-width: 120px;
  }
`

const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
`
export default Receipting
