import Sentry from 'src/utils/sentry'
import { createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'

const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    customers: [],
    customerStats: {
      serverCount: 0,
      isLoading: false,
    },
  },
  reducers: {
    setCustomer: (state, action) => {
      state.customers = action.payload
    },
    setCustomerStats: (state, action) => {
      state.customerStats.serverCount = action.payload.count
    },
    deleteCustomer: (state, action) => {
      state.customers = state.customers.filter(
        item => item._id !== action.payload,
      )
    },
    addCustomer: (state, action) => {
      state.customers.push(action.payload)
    },
    editCustomer: (state, action) => {
      const index = state.customers.findIndex(
        consumer => consumer._id === action.payload._id,
      )
      if (index !== -1) {
        state.customers[index] = action.payload
      }
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
})

export const {
  setCustomer,
  setCustomerStats,
  addCustomer,
  deleteCustomer,
  editCustomer,
  setLoading,
} = customerSlice.actions

export const fetchAllCustomers = supplierId => async dispatch => {
  try {
    dispatch(setLoading(true))
    const result = await API.get('api2', `suppliers/${supplierId}/venues`)

    const customers = result.customer
    const count = customers.length
    dispatch(setCustomer(customers))
    dispatch(setCustomerStats({ count }))
    dispatch(setLoading(false))
  } catch (e) {
    console.error('Error fetching all customers:', e)
    Sentry.captureException(e)
    return false
  }
}

export const adminDeleteCustomer =
  (customer, suppliersId) => async dispatch => {
    try {
      await API.del('api2', `suppliers/${suppliersId}/venues/${customer._id}`)

      dispatch(deleteCustomer(customer._id))
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return false
    }
  }

export const adminAddCustomer = (supplierId, customer) => async dispatch => {
  try {
    const result = await API.post('api2', `suppliers/${supplierId}/venues`, {
      body: customer,
    })

    dispatch(addCustomer(result))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const adminEditCustomer =
  (supplierId, venueId, updateDataConsumer) => async dispatch => {
    try {
      const response = await API.put(
        'api2',
        `suppliers/${supplierId}/venues/${venueId}/`,
        {
          body: updateDataConsumer,
        },
      )

      dispatch(editCustomer(response))
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return false
    }
  }

export default customerSlice
