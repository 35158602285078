import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import styled from 'styled-components'

const InfoPanel = ({
  addCustomerModalVisible,
  searchVenueName,
  addingCustomer,
  setAddingCustomer,
}) => {
  const [venues, setVenues] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (searchVenueName.trim() !== '') {
          const result = await API.get(
            'api2',
            `venues?venueTitle=${searchVenueName}`,
          )

          if (result) {
            setVenues([])
            const venuesArray = result.customer.map(element => element)
            setVenues(venuesArray)
          } else {
            setVenues([])
          }
        } else {
          setVenues([])
        }
      } catch (error) {
        console.error('Error:', error)

        setVenues([])
      }
    }

    setTimeout(() => {
      fetchData()
    }, 1000)
  }, [searchVenueName])

  return (
    <div>
      {addCustomerModalVisible && venues.length > 0 && (
        <Container>
          <Title>Exist venues</Title>
          <VenueListContainer>
            <VenueList>
              {venues.map(venue => (
                <VenueItem
                  key={venue._id}
                  onClick={() => {
                    setAddingCustomer({
                      ...addingCustomer,
                      venueName: venue.venueTitle,
                      addressCity: venue.addressCity,
                      addressLine1: venue.addressLine1,
                      addressLine2: venue.addressLine2,
                      addressPostCode: venue.addressPostCode,
                      accountNumber: venue.accountNumber,
                      deliveryDays: venue.deliveryDays,
                      minimumOrder: venue.minimumOrder,
                      deliveryFee: venue.deliveryFree,
                      orderCutOffTime: venue.orderCutOff,
                    })
                  }}
                >
                  <VenueImage
                    src={venue.logo ? venue.logo : '/apple-touch-icon.png'}
                    alt='logo'
                  />
                  <VenueDetails>
                    <VenueTitle>{venue.venueTitle}</VenueTitle>
                    <VenueCity>{venue.addressCity}</VenueCity>
                    <VenueAddress>{venue.addressLine1}</VenueAddress>
                    <VenueAddress>{venue.addressLine2}</VenueAddress>
                    <VenueAddress>{venue.addressPostCode}</VenueAddress>
                  </VenueDetails>
                </VenueItem>
              ))}
            </VenueList>
          </VenueListContainer>
        </Container>
      )}
    </div>
  )
}

const Container = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
`

const Title = styled.h3`
  font-size: 20px;
  margin-bottom: 15px;
`

const VenueListContainer = styled.div`
  max-height: 300px;
  width: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
`

const VenueList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`

const VenueItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  cursor: pointer;
  padding-left: 5px;
  border-radius: 5px;

  &:hover {
    background-color: #fefff0;
  }
`

const VenueImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 15px;
  border: 1px solid #ddd;
`

const VenueDetails = styled.div``

const VenueTitle = styled.h3`
  margin: 0;
  font-size: 16px;
  color: #333;
`

const VenueCity = styled.h4`
  margin: 5px 0;
  font-size: 14px;
  color: #666;
`

const VenueAddress = styled.p`
  margin: 3px 0;
  font-size: 12px;
  color: #888;
`

export default InfoPanel
