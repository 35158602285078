import { Select, AutoComplete, Table, Button, DatePicker } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CloseOutlined } from '@ant-design/icons'
import styled from 'styled-components'
const { Option } = AutoComplete
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import moment from 'moment'
import { H3, H4 } from 'src/branding/typography'
import { createXeroBill, fetchXeroProducts } from 'src/features/ReceiptingSlice'
import dayjs from 'dayjs'

const SendToXero = () => {
  const venue = useSelector(state => state.venue.venue)
  const xero = venue?.xeroConnection
  const [selectedXeroAccount, setSelectedXeroAccount] = useState(null)
  const [selectedXeroContact, setSelectedXeroContact] = useState(null)
  const [filteredAccounts, setFilteredAccounts] = useState([])
  const location = useLocation()
  const { order } = location.state
  const xeroProducts = useSelector(state => state.receipting.xeroProducts)
  // const receptingOrders = receiptingProducts?.map(item => item.orders)
  const supplier = order?.supplier
  const receptingOrder = xeroProducts
    ?.flat()
    .filter(order => order.supplierConfirmed === true)
  const dispatch = useDispatch()
  const [date, setDate] = useState('')
  const [file, setFile] = useState(null)
  const fileInputRef = useRef(null)
  const [selctedOrganization, setSelectedOrganization] = useState(null)
  const isLoading = useSelector(state => state?.receipting?.isLoading)

  useEffect(() => {
    dispatch(
      fetchXeroProducts(
        venue?._id,
        order?.supplier?._id,
        order?.purchaseOrderNumber,
      ),
    )
  }, [order])

  const onChangeDate = dateString => {
    if (dateString) {
      setDate(dateString)
    } else {
      console.log('error')
    }
  }

  useEffect(() => {
    if (selctedOrganization) {
      const accounts = selctedOrganization?.accounts
      const filteredData = accounts?.filter(
        account => account.TaxType === 'INPUT2',
      )
      setFilteredAccounts(filteredData)
    } else {
      const accounts = xero?.organizations[0]?.accounts
      const filteredData = accounts?.filter(
        account => account.TaxType === 'INPUT2',
      )
      setFilteredAccounts(filteredData)
    }
  }, [selctedOrganization])

  const handleFileChange = e => {
    const selectedFile = e.target.files[0]
    setFile(selectedFile)
  }

  const handleSubmit = async () => {
    const Lineitems = receptingOrder?.map(order => ({
      Description: order?.product?.title,
      Quantity: order?.quantity,
      UnitAmount: order?.product?.price / 100,
      AccountID: selectedXeroAccount,
      TaxType: order?.product?.hasVat ? 'INPUT2' : 'ZERORATEDINPUT',
    }))
    const Contact = selectedXeroContact
      ? {
          ContactID: selectedXeroContact,
        }
      : { Name: supplier?.title }
    const billData = {
      Type: 'ACCPAY',
      Contact,
      Date: dayjs(order?.orderedDate).format('YYYY-MM-DD'),
      DueDate: dayjs(date).format('YYYY-MM-DD'),
      LineAmountTypes: 'Exclusive',
      LineItems: Lineitems,
      Status: 'AUTHORISED',
    }
    const organizationId = selctedOrganization
      ? selctedOrganization.id
      : xero.organizations[0].id

    dispatch(createXeroBill(billData, file, venue?._id, organizationId))
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'product.title',
      key: 'title',
      render: text => <H4>{text}</H4>,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: quantity => <b>{quantity}</b>,
    },
    {
      title: 'Unit Price',
      dataIndex: 'product.price',
      key: 'price',
      render: price => <span>£{(price / 100).toFixed(2)}</span>,
    },
    {
      title: 'Size',
      dataIndex: 'product.size',
      key: 'size',
    },
    {
      title: 'Supplier Confirmed',
      dataIndex: 'supplierConfirmed',
      key: 'supplierConfirmed',
      render: confirmed => <span>{confirmed ? 'Yes' : 'No'}</span>,
    },
  ]

  return (
    <ModalView>
      <StyledCard>
        <H3>Send to Xero</H3>
        {xero?.organizations?.length > 1 ? (
          <>
            <Select
              style={{
                width: '100%',
                padding: 10,
                margin: 2,
              }}
              placeholder={'Select an organization'}
              onChange={value => setSelectedOrganization(value)}
            >
              {xero?.organizations.map(organization => (
                <Option value={organization} key={organization.id}>
                  {organization?.name}
                </Option>
              ))}
            </Select>
            {selctedOrganization && (
              <>
                <H4 style={{ marginLeft: 20 }}>
                  Organization: {selctedOrganization?.name}
                </H4>

                <Select
                  style={{
                    width: '100%',
                    padding: 10,
                    margin: 2,
                  }}
                  placeholder={'Select an account'}
                  onChange={value => setSelectedXeroAccount(value)}
                >
                  {filteredAccounts?.map(account => (
                    <Option value={account?.AccountID} key={account?.AccountID}>
                      {account?.Name}
                    </Option>
                  ))}
                </Select>

                <Select
                  style={{
                    width: '100%',
                    padding: 10,
                    margin: 2,
                  }}
                  placeholder={'Select a contact'}
                  onChange={value => setSelectedXeroContact(value)}
                >
                  {selctedOrganization?.contacts?.map(contact => (
                    <Option value={contact?.ContactID} key={contact.ContactID}>
                      {contact?.Name}
                    </Option>
                  ))}
                </Select>
              </>
            )}
          </>
        ) : (
          <>
            <H4 style={{ marginLeft: 5 }}>
              Organization: {xero?.organizations[0]?.name}
            </H4>

            <Select
              style={{
                width: '100%',
                padding: 10,
                margin: 2,
              }}
              placeholder={'Select an account'}
              onChange={value => setSelectedXeroAccount(value)}
            >
              {filteredAccounts?.map(account => (
                <Option value={account?.AccountID} key={account?.AccountID}>
                  {account?.Name}
                </Option>
              ))}
            </Select>

            <Select
              style={{
                width: '100%',
                padding: 10,
                margin: 2,
              }}
              placeholder={'Select a contact'}
              onChange={value => setSelectedXeroContact(value)}
            >
              {xero?.organizations[0]?.contacts?.map(contact => (
                <Option value={contact?.ContactID} key={contact.ContactID}>
                  {contact?.Name}
                </Option>
              ))}
            </Select>
          </>
        )}

        <OrderDetails>
          <H4>PO Number: {order?.purchaseOrderNumber}</H4>
          <H4>Order Date: {moment(order?.orderedDate).format('YYYY-MM-DD')}</H4>
          <H4>Order Total: £{order?.totalSpendReceived / 100}</H4>
        </OrderDetails>

        <StyledDatePicker
          placeholder='Select Due Date'
          onChange={onChangeDate}
        />

        <FileUpload>
          {file ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'start',
                flexDirection: 'row',
                justifyItems: 'space-around',
              }}
            >
              <img src={URL.createObjectURL(file)} alt='invoice' />

              <CloseOutlined
                onClick={() => setFile(null)}
                style={{ fontSize: '20px', margin: 5 }}
              />
            </div>
          ) : (
            <Button onClick={() => fileInputRef.current.click()}>
              Add Invoice (Optional)
            </Button>
          )}
          <input
            type='file'
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </FileUpload>

        <Table columns={columns} dataSource={receptingOrder} bordered />

        <Button
          loading={isLoading}
          style={{
            background: '#b5d922',
            color: '#fff',
            width: '100%',
          }}
          onClick={handleSubmit}
        >
          Send to Xero
        </Button>
      </StyledCard>
    </ModalView>
  )
}

export default SendToXero

const ModalView = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
`

const StyledCard = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 30px;
  border-radius: 10px;
  text-align: left;
`

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  margin: 20px 0;
  padding: 10px;
`

const FileUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  img {
    max-width: 700px;
    height: 300px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  button {
    background: #b5d922;
    color: #fff;
    width: 100%;
  }
`

const OrderDetails = styled.div`
  margin: 20px 0;
  padding: 15px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  background: #fafafa;
`
