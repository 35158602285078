import { SearchOutlined } from '@ant-design/icons'
import { Col, Input, Row } from 'antd'
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingButton from 'src/components/styled/LoadingButton'
import { setError, setTitle, setTotal } from 'src/features/AnalyticsSearchSlice'

import { useWindowWidth } from 'src/utils/useWindowWidth'
import styled, { ThemeContext } from 'styled-components/macro'
import { performAnalyticsSearch } from 'src/features/AnalyticsSearchSlice'
const SearchAnalytics = () => {
  const dispatch = useDispatch()
  const account = useSelector(state => state.auth.account)
  const supplierId = account?.supplier[0]._id

  const searchTitle = useSelector(state => state.analyticsSearch.title)
  const searchTotal = useSelector(state => state.analyticsSearch.total)
  const searchLoading = useSelector(state => state.analyticsSearch.loading)
  const searchError = useSelector(state => state.analyticsSearch.error)

  const onSearch = () => {
    try {
      if (!searchTitle.trim()) {
        dispatch(setError('Please enter a search term'))
        dispatch(setTitle(''))
      } else {
        dispatch(performAnalyticsSearch(supplierId, searchTitle))
      }
    } catch (error) {
      console.error('Search failed:', error)
    }
  }
  useEffect(() => {
    dispatch(setError(''))
  }, [])

  const windowWidth = useWindowWidth()
  const theme = useContext(ThemeContext)
  const isMobile = windowWidth <= theme.breakpoints.lg

  return (
    <Input.Group size='large' style={{ width: '100%', marginBottom: 20 }}>
      <Row gutter={16}>
        <Col span={isMobile ? 24 : 8}>
          <Input
            prefix={<SearchOutlined />}
            placeholder='Search by...'
            value={searchTitle}
            onChange={event => {
              dispatch(setError(''))

              dispatch(setTotal(0))

              dispatch(setTitle(event.target.value))
            }}
            onPressEnter={onSearch}
            allowClear
          />
          {searchError && <SearchError>{searchError}</SearchError>}
        </Col>
        <Col
          span={isMobile ? 24 : 6}
          style={{
            minHeight: '40px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        ></Col>
        <Col span={isMobile ? 12 : 2}>
          <LoadingButton
            type='primary'
            size='large'
            loading={searchLoading}
            onClick={onSearch}
          >
            Search
          </LoadingButton>
        </Col>
        <Col span={isMobile ? 12 : 8}>
          {Boolean(searchTitle.trim()) && (
            <SearchResultCount>
              {searchTotal}
              {searchTotal === 1 ? ' result' : ' results'}
            </SearchResultCount>
          )}
        </Col>
      </Row>
    </Input.Group>
  )
}

const SearchResultCount = styled.p`
  min-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
`

const SearchError = styled.p`
  color: ${({ theme }) => theme.colours.chilliRed};
`

export default SearchAnalytics
