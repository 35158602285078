import { configureStore } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { combineReducers } from 'redux'
import AccountsSlice from 'src/features/AccountsSlice'
import AdminOrderSlice from 'src/features/AdminOrderSlice'
import AuthSlice from 'src/features/AuthSlice'
import CatalogueSearchSlice from 'src/features/CatalogueSearchSlice'
import ChecklistSlice from 'src/features/ChecklistSlice'
import CreditSlice from 'src/features/CreditSlice'
import FilterSlice from 'src/features/FilterSlice'
import InvoiceSlice from 'src/features/InvoiceSlice'
import OrdersSlice from 'src/features/OrdersSlice'
import ProductSlice from 'src/features/ProductSlice'
import SupplierSlice from 'src/features/SupplierSlice'
import VenueCatalogueSlice from 'src/features/VenueCatalogueSlice'
import VenueProductsSlice from 'src/features/VenueProductsSlice'
import VenueSlice from 'src/features/VenueSlice'
import VenueSuppliersSlice from 'src/features/VenueSuppliersSlice'
import SpendingSlice from 'src/features/SpendingSlice'
import StockSlice from 'src/features/StockSlice'
import history from 'src/history'
import overViewSlice from './OverViewSlice'
import receiptingSlice from 'src/features/ReceiptingSlice'
import NewInvoicesSlice from './NewInvoicesSlice'
import DataplusIntegrationSlice from './DataplusIntagrationSlice'
import weeklySlice from './WeeklySalesSlice'
import customerSearchSlice from './CustomerSearchSlice'
import customerSlice from './CustomerSlice'
import customerAnalyticsSlice from './AnalyticsSlice'
import analyticsSearchSlice from './AnalyticsSearchSlice'
const rootReducer = combineReducers({
  router: connectRouter(history),
  suppliers: SupplierSlice.reducer,
  product: ProductSlice.reducer,
  auth: AuthSlice.reducer,
  venue: VenueSlice.reducer,
  adminOrders: AdminOrderSlice.reducer,
  overView: overViewSlice.reducer,
  venueSuppliers: VenueSuppliersSlice.reducer,
  venueCatalogue: VenueCatalogueSlice.reducer,
  catalogueSearch: CatalogueSearchSlice.reducer,
  venueProducts: VenueProductsSlice.reducer,
  orders: OrdersSlice.reducer,
  checklist: ChecklistSlice.reducer,
  invoice: InvoiceSlice.reducer,
  accounts: AccountsSlice.reducer,
  credit: CreditSlice.reducer,
  filter: FilterSlice.reducer,
  supplierOrder: SpendingSlice.reducer,
  stockTakes: StockSlice.reducer,
  receipting: receiptingSlice.reducer,
  newInvoices: NewInvoicesSlice.reducer,
  dataplus: DataplusIntegrationSlice.reducer,
  sale: weeklySlice.reducer,

  customerSearch: customerSearchSlice.reducer,
  customer: customerSlice.reducer,
  customerAnalytics: customerAnalyticsSlice.reducer,
  analyticsSearch: analyticsSearchSlice.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      routerMiddleware(history),
      // window?.LogRocket?.reduxMiddleware(),
    ]),
  // Note that `thunk` is included as part of the default middleware
})
