import { Input } from 'antd'
import React from 'react'
import styled from 'styled-components/macro'
import DeliveryDaysSelect from 'src/components/DeliveryDaysField'

const EditCustomerModel = ({ customer, setEditedCustomer }) => {
  return (
    <Wrapper>
      <Row>
        <InputComp
          value={customer.logo}
          onChange={e => {
            setEditedCustomer({ ...customer, logo: e.target.value })
          }}
          addonBefore={'Logo'}
          placeholder='Enter your url'
        />
      </Row>
      <Row>
        <InputComp
          value={customer.venueTitle}
          onChange={e => {
            setEditedCustomer({ ...customer, venueTitle: e.target.value })
          }}
          addonBefore={'Venue Title'}
          placeholder='Venue title'
        />
      </Row>

      <Row>
        <InputComp
          value={customer.accountNumber}
          onChange={e => {
            setEditedCustomer({ ...customer, accountNumber: e.target.value })
          }}
          addonBefore={'Account Number'}
          placeholder='Account Number'
        />
      </Row>

      <Row>
        <InputComp
          value={customer.addressCity}
          onChange={e => {
            setEditedCustomer({ ...customer, addressCity: e.target.value })
          }}
          addonBefore={'Address city'}
          placeholder='Address city'
        />
      </Row>
      <Row>
        <InputComp
          value={customer.addressLine1}
          onChange={e => {
            setEditedCustomer({ ...customer, addressLine1: e.target.value })
          }}
          addonBefore={'Address line 1'}
          placeholder='Address line 1'
        />
      </Row>
      <Row>
        <InputComp
          value={customer.addressLine2}
          onChange={e => {
            setEditedCustomer({ ...customer, addressLine2: e.target.value })
          }}
          addonBefore={'Address line 2'}
          placeholder='Address line 2'
        />
      </Row>
      <Row>
        <InputComp
          value={customer.addressPostCode}
          onChange={e => {
            setEditedCustomer({ ...customer, addressPostCode: e.target.value })
          }}
          addonBefore={'Address post code'}
          placeholder='Address post code'
        />
      </Row>
      <Row>
        <DeliveryDaysSelect
          deliveryDays={customer.deliveryDays}
          onChange={value => {
            setEditedCustomer({
              ...customer,
              deliveryDays: value,
            })
          }}
          isDisabled={false}
        />
      </Row>

      <Row>
        <InputComp
          value={customer.minimumOrder}
          onChange={e => {
            setEditedCustomer({ ...customer, minimumOrder: e.target.value })
          }}
          addonBefore={'Minimum Order'}
          placeholder='Minimum Order'
        />
      </Row>
      <Row>
        <InputComp
          value={customer.deliveryFree}
          onChange={e => {
            setEditedCustomer({ ...customer, deliveryFree: e.target.value })
          }}
          addonBefore={'Delivery Free'}
          placeholder='Delivery Free'
        />
      </Row>
      <Row>
        <InputComp
          value={customer.orderCutOff}
          onChange={e => {
            setEditedCustomer({ ...customer, orderCutOff: e.target.value })
          }}
          addonBefore={'Order Cut Off'}
          placeholder='Order Cut Off'
        />
      </Row>
      <Row>
        <InputComp
          value={customer.priceFile}
          onChange={e => {
            setEditedCustomer({ ...customer, priceFile: e.target.value })
          }}
          addonBefore={'Price File'}
          placeholder='Price File'
        />
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const InputComp = styled(Input)``

const Row = styled.div`
  margin: 15px 0;
`
export default EditCustomerModel
