import { API } from 'aws-amplify'
import Sentry from 'src/utils/sentry'
import { createSlice } from '@reduxjs/toolkit'

const customerSearchSlice = createSlice({
  name: 'customerSearch',
  initialState: {
    title: '',
    customers: [],
    total: 0,
    loading: false,
    error: '',
  },
  reducers: {
    setTitle: (state, action) => {
      const title = action.payload
      state.title = title
      if (!title.trim()) {
        state.customers = []
      }
    },
    setCustomer: (state, action) => {
      state.customers = action.payload
    },
    setTotal: (state, action) => {
      state.total = action.payload
    },
    setSearchLoading: (state, action) => {
      state.loading = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
  },
})

export const { setTitle, setCustomer, setTotal, setSearchLoading, setError } =
  customerSearchSlice.actions

export const performCustomerSearch =
  (supplierId, venueTitle) => async dispatch => {
    try {
      dispatch(setSearchLoading(true))
      const resultSearch = await API.get(
        'api2',
        `suppliers/${supplierId}/venues?venueTitle=${venueTitle}`,
      )
      const customers = resultSearch.customer
      dispatch(setCustomer(customers))
      dispatch(setTotal(customers.length))
      dispatch(setSearchLoading(false))
    } catch (e) {
      console.error('Got error response from search endpoint:', e)
      Sentry.captureException(e)
      dispatch(setSearchLoading(false))
      dispatch(
        setError('Something went wrong when searching. Please try again.'),
      )
    }
  }

export default customerSearchSlice
