import Sentry from 'src/utils/sentry'
import { createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'

const customerAnalyticsSlice = createSlice({
  name: 'customerAnalytics',
  initialState: {
    customers: [],
    customerStats: {
      serverCount: 0,
      isLoading: false,
    },
    totalCount: 0,
  },
  reducers: {
    setCustomerAnalytics: (state, action) => {
      state.customers = action.payload
    },
    setCustomerAnalyticsStats: (state, action) => {
      state.customerStats.serverCount = action.payload.count
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
})

export const {
  setCustomerAnalytics,
  setCustomerAnalyticsStats,
  setTotalCount,
  setLoading,
} = customerAnalyticsSlice.actions

export const fetchAllCustomerAnalytics =
  (supplierId, currentPage, itemsPerPage) => async dispatch => {
    try {
      dispatch(setLoading(true))
      const result = await API.get(
        'api2',
        `suppliers/${supplierId}/analytics?page=${currentPage}&limit=${itemsPerPage}`,
      )

      const analyticsList = result.customer
      const totalCount = result.totalVenueSuppliersCount
      const count = analyticsList.length
      dispatch(setCustomerAnalytics(analyticsList))
      dispatch(setCustomerAnalyticsStats({ count }))
      dispatch(setTotalCount(totalCount))
      dispatch(setLoading(false))
    } catch (e) {
      console.error('Error fetching all customers:', e)
      Sentry.captureException(e)
      dispatch(setLoading(false))
      return false
    }
  }

function convertToCSV(array) {
  const headers = Object.keys(array[0])
  const csvRows = array.map(obj =>
    headers.map(header => `"${obj[header]}"`).join(','),
  )
  return [headers.join(','), ...csvRows].join('\n')
}

export const downloadAnalyticsData = async (supplierId, fromDate, toDate) => {
  try {
    const response = await API.get(
      'api2',
      `suppliers/${supplierId}/venueOrders?fromDate=${fromDate}&toDate=${toDate}`,
    )
    const csvContent = convertToCSV(response.customer)
    const blob = new Blob([csvContent], { type: 'text/csv' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = `Analytics_Data_for_period_${fromDate}-${toDate}.csv`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (e) {
    console.error('Error fetching all customers:', e)
    Sentry.captureException(e)
    return false
  }
}
export default customerAnalyticsSlice
