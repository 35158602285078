import { adminAddCustomer } from './CustomerSlice'
import { message } from 'antd'
export const parserCustomerDataCSV = (fileData, dispatch) => {
  const newHeaders = {
    'Email of Customer': 'emailOfCustomer',
    'Account Name': 'accountName',
    'Account Number': 'accountNumber',
    'Address City': 'addressCity',
    'Address Line 1': 'addressLine1',
    'Address Line 2': 'addressLine2',
    'Address Post Code': 'addressPostCode',
    'Venue Name': 'venueName',
    'Delivery Days': 'deliveryDays',
    'Minimum Order': 'minimumOrder',
    'Delivery Fee': 'deliveryFee',
    'Order Cut-Off Time': 'orderCutOffTime',
    'Price File\r': 'supplierid',
  }
  const lines = fileData.split('\n')
  const headers = lines[0].split(',')
  let invalideTemplate = false
  headers.forEach(header => {
    if (!newHeaders[header]) {
      invalideTemplate = true
    }
  })
  if (invalideTemplate) {
    message.error(
      'Incorrectly filled in headings, fill them in according to the template',
    )
    return 'error headers'
  }

  const data = lines.slice(1).map(line => {
    const values = parseCSVLine(line)
    const obj = {}
    headers.forEach((header, index) => {
      obj[newHeaders[header]] = values[index]?.trim()
    })
    return obj
  })

  const result = data.filter(row => Object.keys(row).length === headers.length)

  dispatch(adminAddCustomer(result))
}

function parseCSVLine(line) {
  const regex = /(".*?"|[^",\r\n]+)(?=\s*,|\s*$)/g
  return [...line.matchAll(regex)].map(match =>
    match[0].replace(/(^"|"$)/g, ''),
  )
}
