import React from 'react'
import { Select } from 'antd'

const { Option } = Select

const DeliveryDaysSelect = ({ deliveryDays, isDisabled, onChange }) => {
  return (
    <Select
      mode='multiple'
      allowClear
      style={{ width: '100%' }}
      placeholder='Delivery Days'
      value={deliveryDays}
      disabled={isDisabled}
      onChange={onChange}
    >
      <Option key='mon'>Monday</Option>
      <Option key='tue'>Tuesday</Option>
      <Option key='wed'>Wednesday</Option>
      <Option key='thu'>Thursday</Option>
      <Option key='fri'>Friday</Option>
      <Option key='sat'>Saturday</Option>
      <Option key='sun'>Sunday</Option>
    </Select>
  )
}

export default DeliveryDaysSelect
