import { SearchOutlined } from '@ant-design/icons'
import { Col, Input, Row } from 'antd'
import React, { useContext, useEffect } from 'react'

import LoadingButton from 'src/components/styled/LoadingButton'
import { useWindowWidth } from 'src/utils/useWindowWidth'
import styled, { ThemeContext } from 'styled-components/macro'
import {
  performCustomerSearch,
  setTitle,
  setError,
} from 'src/features/CustomerSearchSlice'
import { useDispatch, useSelector } from 'react-redux'
import { setTotal } from 'src/features/CustomerSearchSlice'
const SearchCustomer = ({ supplierId }) => {
  const dispatch = useDispatch()
  const searchTotal = useSelector(state => state.customerSearch.total)
  const searchTitle = useSelector(state => state.customerSearch.title)
  const searchError = useSelector(state => state.customerSearch.error)

  useEffect(() => {
    dispatch(setError(''))
  }, [])

  const onSearch = () => {
    try {
      if (!searchTitle.trim()) {
        dispatch(setError('Please enter a search term'))
        dispatch(setTitle(''))
      } else {
        dispatch(performCustomerSearch(supplierId, searchTitle))
      }
    } catch (error) {
      console.error('Search failed:', error)
    }
  }

  const windowWidth = useWindowWidth()
  const theme = useContext(ThemeContext)
  const isMobile = windowWidth <= theme.breakpoints.lg
  return (
    <Input.Group size='large' style={{ width: '100%', marginBottom: 20 }}>
      <Row gutter={16}>
        <Col span={isMobile ? 24 : 8}>
          <Input
            prefix={<SearchOutlined />}
            placeholder='Search by...'
            value={searchTitle}
            onChange={e => {
              dispatch(setError(''))
              dispatch(setTotal(0))
              dispatch(setTitle(e.target.value))
            }}
            onPressEnter={onSearch}
            allowClear
          />
          {searchError && <SearchError>{searchError}</SearchError>}
        </Col>

        <Col span={isMobile ? 12 : 2}>
          <LoadingButton type='primary' size='large' onClick={onSearch}>
            Search
          </LoadingButton>
        </Col>
        <Col span={isMobile ? 12 : 8}>
          {Boolean(searchTitle.trim()) && (
            <SearchResultCount>
              {searchTotal} {searchTotal === 1 ? 'result' : 'results'}
            </SearchResultCount>
          )}
        </Col>
      </Row>
    </Input.Group>
  )
}

const SearchResultCount = styled.p`
  min-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
`

const SearchError = styled.p`
  color: ${({ theme }) => theme.colours.chilliRed};
`

export default SearchCustomer
