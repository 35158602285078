import React, { useEffect, useState } from 'react'
import { Modal, TreeSelect, Button, Tooltip } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { DatePicker } from 'antd'
import moment from 'moment'
import styled from 'styled-components'
import { fetchVenueOrders } from 'src/features/OrdersSlice'
import DownloadAllInfo from './DownloadAllInfo'
import { saveAs } from 'file-saver'
import { fetchAccountVenues } from 'src/features/AccountsSlice'

const VenuesReportModal = ({ visibleVenuesModal, setVsibleVenuesModal }) => {
  const dispatchh = useDispatch()
  const venues = useSelector(state => state.accounts.accountVenues)
  const username = useSelector(state => state.auth.cognito.username)
  const [selectedVenueIds, setSelectedVenuesIds] = useState([])
  const dateFormat = 'DD/MM/YYYY'
  const venueOptions = venues?.map(item => ({
    title: item.title,
    value: item._id,
  }))
  const allIds = venueOptions?.map(({ value }) => value)
  const [date, setDate] = useState({
    start: moment().subtract(40, 'days').format(dateFormat),
    end: moment().format(dateFormat),
  })
  const [isLoading, setIsLoading] = useState(false)
  const onChangeDate = date => {
    setDate({ start: date[0], end: date[1] })
  }
  const reportOptions = ['Venues Orders Report', 'Venue Report CSV']
  const [selectedReport, setSelectedReport] = useState(null)
  const handleDownloadCSV = async () => {
    try {
      setIsLoading(true)
      const venueOrders = await fetchVenueOrders(
        Array.from(selectedVenueIds),
        date.start,
        date.end,
      )
      const csvHeaders = headers.map(header => header.label).join(',') + '\n'
      const csvRows = venueOrders
        .map(order => headers.map(header => order[header.key] || '').join(','))
        .join('\n')

      const csvContent = csvHeaders + csvRows
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      saveAs(blob, 'Venues-Orders-Report.csv')
      setSelectedReport(null)
      setSelectedVenuesIds([])
      setVsibleVenuesModal(false)
    } catch (error) {
      console.error('Error generating or downloading CSV:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const headers = [
    { label: 'PO Number', key: 'PO' },
    { label: 'Title', key: 'Title' },
    { label: 'Sku', key: 'sku' },
    { label: 'Quantity', key: 'Quantity' },
    { label: 'Order Date', key: 'OrderDate' },
    { label: 'Item Price', key: 'ItemPrice' },
    { label: 'Total Price', key: 'TotalPrice' },
    { label: 'Venue', key: 'Venue' },
    { label: 'Ordered By', key: 'Buyer' },
    { label: 'Supplier Name', key: 'Supplier' },
    { label: 'Order Status', key: 'Status' },
  ]

  const treeData = reportOptions.map((report, index) => ({
    title: report,
    value: report,
    key: index,
  }))

  useEffect(() => {
    dispatchh(fetchAccountVenues({ username }))
  }, [])

  return (
    <>
      <Modal
        width={'40%'}
        title={'Reports'}
        visible={visibleVenuesModal}
        onCancel={() => {
          setVsibleVenuesModal(false)
        }}
        footer={null}
      >
        <>
          <TreeSelect
            treeNodeFilterProp='title'
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            value={selectedReport}
            dropdownStyle={{ maxHeight: '300px' }}
            style={{ marginBottom: '15px', width: '355px' }}
            placeholder='Report Type'
            allowClear
            treeDefaultExpandAll
            onChange={value => setSelectedReport(value)}
            treeData={treeData}
          />
          {selectedReport && selectedReport === 'Venues Orders Report' && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TreeSelect
                treeNodeFilterProp='title'
                allowClear={true}
                placeholder='Select Venues ...'
                treeCheckable={true}
                showCheckedStrategy={TreeSelect.SHOW_CHILD}
                dropdownStyle={{ maxHeight: '300px' }}
                style={{ marginBottom: '15px', width: '355px' }}
                onChange={ids => setSelectedVenuesIds(ids)}
                value={selectedVenueIds}
                maxTagCount={1}
                maxTagPlaceholder={omittedValues =>
                  `+ ${omittedValues.length} Venues ...`
                }
                treeData={[
                  {
                    title:
                      selectedVenueIds.length > 0 ? (
                        <span
                          onClick={() => setSelectedVenuesIds([])}
                          style={{
                            display: 'inline-block',
                            color: '#b5d922',
                            cursor: 'pointer',
                          }}
                        >
                          Unselect all
                        </span>
                      ) : (
                        <span
                          onClick={() => setSelectedVenuesIds(allIds)}
                          style={{
                            display: 'inline-block',
                            color: '#b5d922',
                            cursor: 'pointer',
                          }}
                        >
                          Select all
                        </span>
                      ),
                    value: 'xxx',
                    disableCheckbox: true,
                    disabled: true,
                  },
                  ...venueOptions,
                ]}
              />
              <StyledRangePicker
                defaultValue={[moment().subtract(40, 'days'), moment()]}
                onChange={(value, string) => onChangeDate(string)}
                format={dateFormat}
              />
              {selectedVenueIds.length === 0 ? (
                <Tooltip title={'Seleect at least one venue'}>
                  <Button
                    style={{ width: '355px', marginTop: 20 }}
                    className='ant-btn ant-btn-primary'
                  >
                    Download CSV Report
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  loading={isLoading}
                  style={{ width: '355px', marginTop: 20 }}
                  className='ant-btn ant-btn-primary'
                  onClick={handleDownloadCSV}
                  disabled={isLoading}
                >
                  Download CSV Report
                </Button>
              )}
            </div>
          )}
          {selectedReport && selectedReport === 'Venue Report CSV' && (
            <>
              <DownloadAllInfo venues={venues} />
            </>
          )}
        </>
      </Modal>
    </>
  )
}

const StyledRangePicker = styled(DatePicker.RangePicker)`
  .ant-calendar-picker-input.ant-input {
    height: 40px;
    padding: 6px 6px;
    font-size: 16px;
    width: 355px;
  }
`

export default VenuesReportModal
