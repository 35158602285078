import FadeIn from 'src/components/styled/FadeIn'
import Title from 'src/branding/Title'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { DatePicker, Table } from 'antd'
import styled from 'styled-components'
import SearchAnalytics from './SearchAnalytics'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchAllCustomerAnalytics,
  downloadAnalyticsData,
} from 'src/features/AnalyticsSlice'
import NumerCustomer from '../customers/NumberCustomer'

const Analytics = () => {
  const account = useSelector(state => state.auth.account)
  const supplierId = account?.supplier[0]._id
  const customerAnalytics = useSelector(
    state => state.customerAnalytics?.customers || [],
  )
  const pageLoading = useSelector(state => state.customerAnalytics.isLoading)
  const totalCount = useSelector(state => state.customerAnalytics.totalCount)
  const disableEndDate = current => current.valueOf() > moment.now()
  const dateFormat = 'YYYY-MM-DD'
  const weeklyDate = [moment().subtract(7, 'days'), moment()]

  const searchTitle = useSelector(state => state.analyticsSearch.title)
  const searchAnalytics = useSelector(state => state.analyticsSearch.customers)
  const searchLoading = useSelector(state => state.analyticsSearch.loading)
  const isSearchMode = Boolean(searchTitle.trim())
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const [dates, setDates] = useState({ fromdate: '', toDate: '' })
  const [errorPeriod, setErrorPeriod] = useState('')

  const onDateSelection = (value, dateString) => {
    setDates({ fromdate: dateString[0], toDate: dateString[1] })

    if (dateString[0] !== '' && dateString[1] !== '') {
      setErrorPeriod('')
    }
  }

  const habdlerDownloadData = async () => {
    if (dates.fromdate === '' || dates.toDate === '') {
      setErrorPeriod('Select a date first')
    } else {
      await downloadAnalyticsData(supplierId, dates.fromdate, dates.toDate)
    }
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAllCustomerAnalytics(supplierId, currentPage, pageSize))
  }, [dispatch, supplierId, currentPage])

  const displayAnalytics =
    (isSearchMode ? searchAnalytics : customerAnalytics) || []

  const activeCustomer = displayAnalytics.filter(
    item => item.active === 'green',
  )

  const paginationConfig = {
    current: currentPage,
    pageSize: pageSize,
    total: totalCount,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20', '50'],
    onChange: page => setCurrentPage(page),
    onShowSizeChange: (_, size) => {
      setPageSize(size)
      setCurrentPage(1)
    },
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} customer`,
  }
  return (
    <Wrapper>
      <Title>Analytics</Title>
      <WhiteLine>
        <ButtonContainer>
          <LeftButtons>
            <StyledRangePicker
              disabledDate={disableEndDate}
              format={dateFormat}
              defaultValue={weeklyDate}
              onChange={onDateSelection}
            />
          </LeftButtons>

          {errorPeriod && <SearchError>{errorPeriod}</SearchError>}
          <RightButton>
            <button onClick={habdlerDownloadData}>Download Data</button>
          </RightButton>
        </ButtonContainer>
      </WhiteLine>

      <SearchContainer>
        <SearchAnalytics />
      </SearchContainer>

      <NumerCustomer arryCusotmer={activeCustomer}></NumerCustomer>
      <Table
        scroll={{
          scrollToFirstRowOnChange: true,
          x: true,
        }}
        dataSource={displayAnalytics.map(customer => ({
          key: customer._id,
          ...customer,
        }))}
        pagination={paginationConfig}
        loading={pageLoading || searchLoading}
        columns={[
          {
            title: 'Logo',
            key: 'logo',
            className: 'restrictShrink',
            render: record => {
              const { logo } = record
              return (
                <img
                  src={logo === 'No logo' ? '/apple-touch-icon.png' : logo}
                  alt='logo'
                  style={{ width: '50px', height: '50px', borderRadius: '5px' }}
                ></img>
              )
            },
          },
          {
            title: 'Venue name',
            dataIndex: 'venueTitle',
            key: 'venueTitle',
            className: 'restrictShrink',
          },
          {
            title: 'Account Number',
            dataIndex: 'accountNumber',
            key: 'accountNumber',
            className: 'restrictShrink',
          },
          {
            title: 'Total Orders',
            dataIndex: 'totalOrders',
            key: 'totalOrders',
            className: 'restrictShrink',
            render: totalOrders => {
              return totalOrders
            },
          },

          {
            title: 'Total Revenue',
            dataIndex: 'totalRevenue',
            key: 'totalRevenue',
            className: 'restrictShrink',
            render: totalRevenue => {
              if (totalRevenue == null || isNaN(totalRevenue)) {
                return '£0.00'
              }
              return `£${Number(totalRevenue).toLocaleString('en-GB', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            },
          },
          {
            title: 'Average Order Size',
            dataIndex: 'averageOrderSize',
            key: 'averageOrderSize',
            className: 'restrictShrink',
            render: averageOrderSize => {
              if (averageOrderSize == null || isNaN(averageOrderSize)) {
                return '£0.00'
              }
              return `£${Number(averageOrderSize).toLocaleString('en-GB', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            },
          },
          {
            title: 'Last Order Placed',
            dataIndex: 'lastOrderPlaced',
            key: 'lastOrderPlaced',
            className: 'restrictShrink',
          },
          {
            title: 'Active?',
            dataIndex: 'active',
            key: 'active',
            className: 'restrictShrink',
            render: active => {
              return (
                <div
                  style={{
                    width: '15px',
                    height: '15px',
                    backgroundColor: active,
                    borderRadius: '10px',
                    border: '1px solid white',
                  }}
                ></div>
              )
            },
          },
        ]}
      />
    </Wrapper>
  )
}
const StyledRangePicker = styled(DatePicker.RangePicker)`
  .ant-calendar-picker-input.ant-input {
    height: 40px;
    padding: 6px 6px;
    font-size: 16px;
  }
`

const SearchContainer = styled.div`
  position: relative;
  top: 80%;
  margin-top: 30px;
  margin-left: 5px;
`

const Wrapper = styled(FadeIn)`
  .restrictShrink {
    min-width: 110px;
  }
`

const WhiteLine = styled.div`
  position: relative;
  background-color: white;
  width: 99%;
  height: 80px;
  margin: 10px auto;
  display: flex;
  align-items: center;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;

  button {
    background-color: ${({ theme }) => theme.colours.lettuceGreen || 'green'};
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      background-color: #b5d922;
      opacity: 0.8;
      cursor: pointer;
    }
  }
`

const LeftButtons = styled.div`
  display: flex;
  gap: 10px;
`
const SearchError = styled.p`
  color: ${({ theme }) => theme.colours.chilliRed};
`

const RightButton = styled.div``

export default Analytics
