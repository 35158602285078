import React from 'react'
import styled from 'styled-components'
import HeaderScreen from 'src/components/shared/header-screen/HeaderScreen'
import { useDispatch, useSelector } from 'react-redux'
import { disConectXero, xeroIntegration } from 'src/features/VenueSlice'
import { Button } from 'antd'

const Integrations = () => {
  const venueId = useSelector(state => state.venue?.venue?._id)
  const venue = useSelector(state => state.venue?.venue)
  const isLoading = useSelector(state => state.venue?.isLoading)
  const dispatch = useDispatch()
  return (
    <Wrapper>
      <HeaderScreen title='Your Active Integrations' />
      <Section>
        <SectionHeader>
          <h2>Xero Account</h2>
        </SectionHeader>
        <Card>
          <p>
            {venue?.xeroConnection?.accessToken
              ? "You've connnected your Xero Account that helps automatically create bills, attach invoices, and simplify your accounting workflow!"
              : 'Connect your Xero account to automatically create bills, attach invoices, and simplify your accounting workflow!'}
          </p>
          <Button
            style={{
              background: venue?.xeroConnection?.accessToken
                ? '#ff4d4d'
                : '#b5d922',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              marginTop: '10px',
            }}
            loading={isLoading}
            isConnected={venue?.xeroConnection?.accessToken}
            onClick={async () => {
              {
                venue?.xeroConnection?.accessToken
                  ? dispatch(disConectXero(venueId))
                  : dispatch(xeroIntegration(venueId))
              }
            }}
          >
            <span>
              {venue?.xeroConnection?.accessToken
                ? 'Disconnect Xero'
                : 'Connect Xero Account'}
            </span>
          </Button>
        </Card>
      </Section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  min-height: 100vh;
`

const Section = styled.div`
  margin-bottom: 20px;
`

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const Card = styled.div`
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
`

export default Integrations
