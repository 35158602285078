import { createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import Sentry from 'src/utils/sentry'

const initialState = {
  venueSuppliers: [],
  isSomeVenueSupplierActive: null,
  isLoading: false,
}

const venueSuppliersSlice = createSlice({
  name: 'venueSuppliers',
  initialState,
  reducers: {
    setVenueSupplierToState: (state, action) => {
      state.venueSuppliers = action.payload
      state.isSomeVenueSupplierActive = state.venueSuppliers.some(
        venueSupplier =>
          venueSupplier.isActive === true &&
          venueSupplier.isCatalogueAccessible === true,
      )
    },
    editVenueSupplierInState: (state, action) => {
      const index = state.venueSuppliers.findIndex(
        venueSuppliers => venueSuppliers._id === action.payload._id,
      )
      if (index !== -1) {
        state.venueSuppliers[index] = action.payload
        state.isSomeVenueSupplierActive = state.venueSuppliers.some(
          venueSupplier =>
            venueSupplier.isActive === true &&
            venueSupplier.isCatalogueAccessible === true,
        )
      }
    },
    resetVenueSuppliersState: state => {
      state = initialState
      return state
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
})

export const {
  setVenueSupplierToState,
  editVenueSupplierInState,
  resetVenueSuppliersState,
  setIsLoading,
} = venueSuppliersSlice.actions

export const getVenueSuppliers = venueId => async dispatch => {
  try {
    dispatch(setIsLoading(true))
    const _venueSuppliers = await API.get('api', `venue/${venueId}/suppliers`)
    const filteredSuppliers = _venueSuppliers?.filter(
      supplier => supplier && supplier?.nonOMSSupplier !== true,
    )
    dispatch(setVenueSupplierToState(filteredSuppliers))
    dispatch(setIsLoading(false))
  } catch (e) {
    dispatch(setIsLoading(false))
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const editVenueSupplier = data => async dispatch => {
  try {
    const _venueSupplier = await API.post(
      'api',
      `venue/${data.venue}/supplier`,
      {
        body: data,
      },
    )
    dispatch(editVenueSupplierInState(_venueSupplier))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const editVenueSuppliers = data => async dispatch => {
  try {
    const venueSuppliers = await API.post(
      'api',
      `venue/${data.venue}/suppliers`,
      {
        body: data,
      },
    )
    dispatch(setVenueSupplierToState(venueSuppliers))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const resetVenueSuppliers = () => dispatch =>
  dispatch(resetVenueSuppliersState())

export const getVenueByVenueSupplierId = async venueSupplierId => {
  try {
    const venue = await API.get('api', `venue/${venueSupplierId}`)

    return venue
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export default venueSuppliersSlice
