import { createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import Sentry from 'src/utils/sentry'
import dayjs from 'dayjs'
const initialState = {
  orders: [],
  basket: [],
  statementOrders: [],
  filteredStatementOrders: [],
  filtered: [],
  orderedProduct: [],
  creditNotes: [],
  creditNotesTotal: 0,
  creditNotesPagination: { page: 1, limit: 50 },
  isLoading: false,
  isUpdateLoading: false,
  isCreditHistoryLoading: false,
  venueOrders: [],
}

const filterOrders = orders => {
  const filterredOrders = orders?.map(order => {
    const quantity =
      order?.receipting?.previousQty ||
      order.updatedQuantity ||
      order?.quantity ||
      1
    const price =
      (order?.price != 'NaN' && order?.price) ||
      order?.product?.price ||
      (order?.OMSPrice != 'NaN' && order?.OMSPrice) ||
      (order?.OMSSpecialPrice != 'NaN' && order?.OMSSpecialPrice) ||
      0
    return {
      PO: order?.purchaseOrderNumber,
      Title: order?.title || order?.product?.title,
      sku: order?.product?.sku || '',
      Quantity: quantity,
      OrderDate: dayjs(order?.orderedDate).format('DD/MM/YYYY'),
      ItemPrice: `£${price}`,
      ToalPrice: `£${Number(price * quantity).toFixed(2)}`,
      Venue: order?.venue?.title,
      Buyer: order?.placedVia ?? 'Placed using a venue code',
      Supplier: order?.product?.supplier?.title,
      Status:
        order?.supplierConfirmed === true
          ? 'Confirmed'
          : order?.supplierConfirmed === undefined
          ? 'Order Received'
          : order?.supplierConfirmed === false
          ? 'Rejected'
          : '',
    }
  })
  return filterredOrders
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setStatementOrders: (state, action) => {
      state.statementOrders = action.payload
      state.filteredStatementOrders = state.statementOrders
    },
    setVenueOrders: (state, action) => {
      state.venueOrders = action.payload
    },
    set: (state, action) => {
      state.orders = []
      state.basket = []
      if (action.payload && action.payload.length > 0)
        action.payload
          ?.filter(order => order?.nonOMSSupplier !== true)
          .reverse()
          .map(order =>
            order.isOrdered
              ? state.orders.push(order)
              : state.basket.push(order),
          )
      state.filtered = state.orders
    },
    edit: (state, action) => {
      const index = state.orders.findIndex(
        order => order._id === action.payload._id,
      )
      state.orders[index] = action.payload
    },
    setBasket: (state, action) => {
      state.basket = action.payload
    },
    updateOrder: (state, action) => {
      const index = state.basket.findIndex(
        order => order._id === action.payload._id,
      )
      if (index >= 0) {
        if (action.payload.quantity === 0) state.basket.splice(index, 1)
      } else state.basket.push(action.payload)
    },
    updateFilteredStatement: (state, action) => {
      state.filteredStatementOrders = action.payload
    },
    updateFilteredOrders: (state, action) => {
      state.filtered = [...action.payload]
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setUpdateLoading: (state, action) => {
      state.isUpdateLoading = action.payload
    },
    setCreditHistoryLoading: (state, action) => {
      state.isCreditHistoryLoading = action.payload
    },
    setOrderedProduct: (state, action) => {
      state.orderedProduct = [...action.payload]
    },
    setCreditNotes: (state, action) => {
      state.creditNotes = [...action.payload]
    },
    setCreditNotesPagination: (state, action) => {
      state.creditNotesPagination = action.payload
    },
    setCreditNotesTotal: (state, action) => {
      state.creditNotesTotal = action.payload
    },
    resetOrders: state => {
      state = initialState
      return state
    },
    setLoadingElementId: (state, action) => {
      state.loadingElementId = action.payload
    },
    updateCreditsNotes: (state, action) => {
      const { receipting } = action.payload

      state.creditNotes = state.creditNotes.map(orderNote => {
        if (receipting._id === orderNote._id.receipting[0]._id) {
          return {
            _id: {
              ...orderNote._id,
              receipting: [receipting],
            },
          }
        }
        return orderNote
      })
    },
    findOrdersAndUpdate: (state, action) => {
      state.orders = state.filtered.map(order => {
        const foundedOrder = action.payload.find(
          receipting => order._id === receipting.order,
        )
        const res =
          foundedOrder !== undefined
            ? { ...order, receipting: foundedOrder._id }
            : order

        return res
        // return order;
      })
    },
  },
})

export const {
  set,
  setVenueOrders,
  edit,
  setBasket,
  updateFilteredOrders,
  updateFilteredStatement,
  updateOrder,
  setLoading,
  setUpdateLoading,
  resetOrders,
  setOrderedProduct,
  setStatementOrders,
  findOrdersAndUpdate,
  setCreditNotesPagination,
  setCreditNotes,
  setCreditHistoryLoading,
  setCreditNotesTotal,
  updateCreditsNotes,
} = ordersSlice.actions

export const createOrder = body => async dispatch => {
  dispatch(setUpdateLoading(true))
  await API.post('api', `order`, {
    body,
  }).then(order => {
    dispatch(updateOrder(order))
    return order
  })
  dispatch(setUpdateLoading(false))
}

export const findOrderAndUpdateState = receiptingArr => async dispatch => {
  dispatch(findOrdersAndUpdate(receiptingArr))
}

export const fetchStatementOrders =
  (venueId = '', start = '', end = '') =>
  async dispatch => {
    const normalizedStart = start.split('/').reverse().join('-')
    const normalizedEnd = end.split('/').reverse().join('-')
    await API.get(
      'api',
      `venue/${venueId}/statements?start=${normalizedStart}&end=${normalizedEnd}`,
    ).then(data => {
      dispatch(setStatementOrders(data))
      return data
    })
  }

export const getCreditHistory = (venueId, pagination) => async dispatch => {
  try {
    dispatch(setCreditHistoryLoading(true))
    const result = await API.get(
      'api',
      `receipting/${venueId}/credits-history?page=${pagination.page}&limit=${pagination.limit}`,
    )

    dispatch(setCreditNotes(result.ordersArr))
    dispatch(setCreditNotesTotal(result.ordersTotalCount))
    dispatch(setCreditNotesPagination(pagination))
    dispatch(setCreditHistoryLoading(false))
  } catch (e) {
    console.log(e)
    Sentry.captureException(e)
    dispatch(setCreditHistoryLoading(false))

    return false
  }
}
export const clearCreditNotesHistory = () => async dispatch => {
  dispatch(setCreditNotes([]))
}
export const sendOrder = (venueId, body) => async dispatch => {
  dispatch(setLoading(true))

  await API.post('api', `venue/${venueId}/orders-router`, {
    body,
  })
    .then(() => {
      dispatch(setBasket([]))
      dispatch(fetchOrders(venueId))
      dispatch(setLoading(false))
      return
    })
    .catch(error => {
      dispatch(setLoading(false))
      console.error(error)
    })
}

export const fetchOrders =
  (venueId = '', start = '', end = '') =>
  async dispatch => {
    try {
      dispatch(setLoading(true))
      if (venueId && start && end) {
        const normalizedStart = start.split('/').reverse().join('-')
        const normalizedEnd = end.split('/').reverse().join('-')
        const url = `venue/${venueId}/orders?start=${normalizedStart}&end=${normalizedEnd}`
        const data = await API.get('api', url)
        dispatch(set(data))
        dispatch(setLoading(false))
      } else if (venueId) {
        const url = `venue/${venueId}/orders`
        const data = await API.get('api', url)
        const filteredOrders = data.filter(
          order => order && order?.nonOMSSupplier !== true,
        )
        dispatch(set(filteredOrders))
        dispatch(setLoading(false))
      }
    } catch (e) {
      dispatch(setLoading(false))
      console.error(e)
      Sentry.captureException(e)
      return false
    }
  }
export const fetchVenueOrders = async (venueIds = [], start = '', end = '') => {
  try {
    if (venueIds.length && start && end) {
      const normalizedStart = start.split('/').reverse().join('-')
      const normalizedEnd = end.split('/').reverse().join('-')
      const url = `venues/orders?start=${normalizedStart}&end=${normalizedEnd}`
      const { data } = await API.post('api2', url, {
        body: { venueIds },
      })
      const newOrders = filterOrders(data)
      return newOrders
    }
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const editOrder = data => async dispatch => {
  try {
    const _order = await API.post('api', `order`, {
      body: data,
    })

    dispatch(edit(_order))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}
export const updateFiltered = array => dispatch =>
  dispatch(updateFilteredOrders(array))

export const updateStatementFiltered = array => dispatch => {
  dispatch(updateFilteredStatement(array))
}
export default ordersSlice
