import AntDIcon from 'antd/lib/icon'
import React from 'react'

const Icon = ({ size }) => {
  const SVG = () => (
    <svg
      width={size}
      height={size}
      fill='#000000'
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 490 490'
    >
      <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        {' '}
        <g>
          {' '}
          <g>
            {' '}
            <path d='M446.52,174.433c-27.254-20.921-63.341-32.443-101.616-32.443c-23.143,0-45.482,4.22-65.555,12.175 c-10.033-19.097-26.771-36.009-48.063-48.316c-25.068-14.491-54.873-22.15-86.189-22.15c-38.275,0-74.362,11.522-101.616,32.443 C15.441,137.664,0,166.525,0,197.406c0,27.813,12.723,54.284,35.936,75.01l0.395,67.755c0.02,3.536,1.814,6.968,4.961,8.58 c3.736,1.915,7.299,1.457,9.912,0.094l70.288-39.229c7.793,0.996,15.715,1.5,23.605,1.5c0.002,0,0.004,0,0.006,0 c22.85,0,45.271-4.194,65.465-12.174c7.369,14.116,18.396,27.029,32.719,38.024c27.254,20.921,63.342,32.442,101.617,32.442 c7.928,0,15.895-0.51,23.729-1.516l70.376,37.09c3.994,2.034,6.955,1.537,9.803-0.27c2.949-1.871,4.838-5.024,4.859-8.518 l0.395-65.485C477.277,309.984,490,283.511,490,255.699C490,224.818,474.559,195.958,446.52,174.433z M145.102,291.115 c-0.002,0-0.006,0-0.006,0c-8.143,0-16.324-0.602-24.314-1.786c-2.861-0.422-5.617,0.407-7.713,2.082L56.23,323.135l-0.32-55.185 c0.004-0.2,0-0.402-0.01-0.604c-0.131-2.81-1.438-5.435-3.604-7.231C31.471,242.833,20,220.562,20,197.406 c0-51.671,56.119-93.708,125.097-93.708c51.104,0,97.307,23.872,116.152,59.002c-6.324,3.5-12.334,7.413-17.963,11.733 c-28.039,21.524-43.48,50.385-43.48,81.266c0,8.404,1.154,16.657,3.383,24.656C185.337,287.409,165.442,291.115,145.102,291.115z M437.703,318.408c-2.164,1.796-3.471,4.423-3.602,7.232c-0.01,0.182-0.012,0.363-0.012,0.544l-0.32,53.429l-57.021-30.051 c-2.072-1.581-4.75-2.352-7.533-1.94c-7.992,1.186-16.172,1.787-24.313,1.787c-56.488,0-104.342-28.194-119.824-66.782 c-0.064-0.188-0.146-0.371-0.223-0.557c-3.281-8.369-5.051-17.218-5.051-26.371c0-33.247,23.238-62.503,58.182-79.141 c0.025-0.01,0.051-0.016,0.076-0.026c0.391-0.154,0.762-0.338,1.123-0.535c19.109-8.874,41.627-14.007,65.717-14.007 c68.978,0,125.097,42.038,125.097,93.709C470,278.854,458.529,301.125,437.703,318.408z'></path>
            <path d='M403.65,222.195H286.155c-5.523,0-10,4.477-10,10c0,5.523,4.477,10,10,10H403.65c5.521,0,10-4.477,10-10 C413.65,226.672,409.172,222.195,403.65,222.195z'></path>
            <path d='M403.65,267.469H286.155c-5.523,0-10,4.477-10,10c0,5.523,4.477,10,10,10H403.65c5.521,0,10-4.477,10-10 C413.65,271.946,409.172,267.469,403.65,267.469z'></path>{' '}
          </g>
          <g>
            {' '}
            <path d='M446.52,174.433c-27.254-20.921-63.341-32.443-101.616-32.443c-23.143,0-45.482,4.22-65.555,12.175 c-10.033-19.097-26.771-36.009-48.063-48.316c-25.068-14.491-54.873-22.15-86.189-22.15c-38.275,0-74.362,11.522-101.616,32.443 C15.441,137.664,0,166.525,0,197.406c0,27.813,12.723,54.284,35.936,75.01l0.395,67.755c0.02,3.536,1.814,6.968,4.961,8.58 c3.736,1.915,7.299,1.457,9.912,0.094l70.288-39.229c7.793,0.996,15.715,1.5,23.605,1.5c0.002,0,0.004,0,0.006,0 c22.85,0,45.271-4.194,65.465-12.174c7.369,14.116,18.396,27.029,32.719,38.024c27.254,20.921,63.342,32.442,101.617,32.442 c7.928,0,15.895-0.51,23.729-1.516l70.376,37.09c3.994,2.034,6.955,1.537,9.803-0.27c2.949-1.871,4.838-5.024,4.859-8.518 l0.395-65.485C477.277,309.984,490,283.511,490,255.699C490,224.818,474.559,195.958,446.52,174.433z M145.102,291.115 c-0.002,0-0.006,0-0.006,0c-8.143,0-16.324-0.602-24.314-1.786c-2.861-0.422-5.617,0.407-7.713,2.082L56.23,323.135l-0.32-55.185 c0.004-0.2,0-0.402-0.01-0.604c-0.131-2.81-1.438-5.435-3.604-7.231C31.471,242.833,20,220.562,20,197.406 c0-51.671,56.119-93.708,125.097-93.708c51.104,0,97.307,23.872,116.152,59.002c-6.324,3.5-12.334,7.413-17.963,11.733 c-28.039,21.524-43.48,50.385-43.48,81.266c0,8.404,1.154,16.657,3.383,24.656C185.337,287.409,165.442,291.115,145.102,291.115z M437.703,318.408c-2.164,1.796-3.471,4.423-3.602,7.232c-0.01,0.182-0.012,0.363-0.012,0.544l-0.32,53.429l-57.021-30.051 c-2.072-1.581-4.75-2.352-7.533-1.94c-7.992,1.186-16.172,1.787-24.313,1.787c-56.488,0-104.342-28.194-119.824-66.782 c-0.064-0.188-0.146-0.371-0.223-0.557c-3.281-8.369-5.051-17.218-5.051-26.371c0-52.682,56.998-93.709,125.098-93.709 S470,204.028,470,255.699C470,278.854,458.529,301.125,437.703,318.408z'></path>
            <path d='M403.65,222.195H286.155c-5.523,0-10,4.477-10,10c0,5.523,4.477,10,10,10H403.65c5.521,0,10-4.477,10-10 C413.65,226.672,409.172,222.195,403.65,222.195z'></path>
            <path d='M403.65,267.469H286.155c-5.523,0-10,4.477-10,10c0,5.523,4.477,10,10,10H403.65c5.521,0,10-4.477,10-10 C413.65,271.946,409.172,267.469,403.65,267.469z'></path>{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  )
  return <AntDIcon component={SVG} />
}
export default Icon
