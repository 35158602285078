import styled from 'styled-components'

const NumerCustomer = ({ arryCusotmer }) => {
  return (
    <DivNumer>
      <h3>Active Customers: {arryCusotmer.length}</h3>
    </DivNumer>
  )
}
const DivNumer = styled.div`
  background-color: rgb(161, 161, 161);
  border-radius: 5px;
  padding: 10px;
  display: inline-block;
  color: white;
  height: 5%;
  margin-left: 7px;
  margin-bottom: 10px;
`

export default NumerCustomer
