import React, { useEffect, useState } from 'react'
import { getCreditHistory } from '../../features/OrdersSlice'
import Loading from '../../components/styled/Loading'
import { Table, Input, Button } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { makeOrderResolved } from '../../features/ReceiptingSlice'
import styled from 'styled-components'
import CreditNotesColumns from './CreditNotesColums'
import CreditNotesCSV from './CreditNotesCSV'

const { Search } = Input
// const [searchData, setSearchData] = useState([]);
const CreditNotes = props => {
  const dispatch = useDispatch()
  const {
    creditNotes,
    creditNotesTotal,
    isCreditHistoryLoading,
    creditNotesPagination,
  } = useSelector(state => state.orders)
  const { venueId } = props

  const [dataSourse, setDataSourse] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [isBtnDisabled, setIsBtnDisabled] = useState(false)

  useEffect(() => {
    dispatch(getCreditHistory(venueId, creditNotesPagination))
  }, [])

  useEffect(() => {
    if (searchQuery === '') {
      setIsBtnDisabled(true)
    } else {
      setIsBtnDisabled(false)
    }
  }, [searchQuery])

  useEffect(() => {
    filterCreditNotes(unResolvedNotes)
  }, [creditNotes])

  const makeOrderResolvedValue = receiptingId => {
    dispatch(makeOrderResolved(receiptingId))
  }

  const unResolvedNotes = creditNotes.filter(
    orderNote => orderNote?._id.receipting[0].orderResolved === false,
  )

  const filterCreditNotes = creditNotes => {
    const filteredArr = creditNotes.map(orderNote => {
      const quantity = orderNote._id.receipting[0].updatedQuantity
        ? orderNote._id.receipting[0].updatedQuantity
        : orderNote._id.quantity
      return {
        PON: orderNote._id.purchaseOrderNumber,
        SKU: orderNote._id.sku,
        productTitle: orderNote._id.title,
        supplierTitle: orderNote._id.supplierTitile[0],
        quantity: quantity,
        amount: (
          (orderNote._id.price * orderNote._id.receipting[0].updatedQuantity) /
          100
        ).toFixed(2),
        reportStatus: orderNote._id.receipting[0].error,
        createdAt: orderNote._id.orderedDate,
        resolved: orderNote?._id.receipting[0].orderResolved || false,
        receiptingId: orderNote?._id.receipting[0]._id,
      }
    })

    setDataSourse(filteredArr)
  }
  const onSearch = () => {
    const filteredArr = dataSourse.filter(el => el.PON === searchQuery)
    setDataSourse(filteredArr)
  }

  return (
    <>
      {isCreditHistoryLoading ? (
        <Loading />
      ) : (
        <>
          <TableWrapper>
            <StyledSearchBarWrapper>
              <Search
                size='large'
                placeholder='Search by PO Number'
                style={{ width: 300 }}
                type='text'
                value={searchQuery}
                allowClear
                onPressEnter={onSearch}
                onChange={e => {
                  setSearchQuery(e.target.value)
                  if (e.target.value === '') {
                    filterCreditNotes(unResolvedNotes)
                  }
                }}
              />
              <Button
                disabled={isBtnDisabled}
                onClick={onSearch}
                style={{
                  opacity: isBtnDisabled ? 0.4 : 1,
                  color: '#ffffff',
                  backgroundColor: '#b5d922',
                  borderColor: '#b5d922',
                  height: 40,
                  width: 80,
                }}
              >
                Search
              </Button>

              <div style={{ marginLeft: 'auto' }}>
                <CreditNotesCSV creditNotes={dataSourse} />
              </div>
            </StyledSearchBarWrapper>

            <StyledTable
              dataSource={dataSourse.map((order, index) => ({
                key: index,
                ...order,
              }))}
              columns={CreditNotesColumns(makeOrderResolvedValue)}
              pagination={{
                position: 'both',
                total: creditNotesTotal,
                pageSize: creditNotesPagination.limit,
                pageSizeOptions: ['10', '20', '50', '100', '250'],
                showSizeChanger: true,
                current: creditNotesPagination.page,
                onChange: page => {
                  dispatch(
                    getCreditHistory(venueId, {
                      page: page,
                      limit: creditNotesPagination.limit,
                    }),
                  )
                },
                onShowSizeChange: (_, pageSize) =>
                  dispatch(
                    getCreditHistory(venueId, {
                      page: creditNotesPagination.page,
                      limit: pageSize,
                    }),
                  ),
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} results`,
              }}
            />
          </TableWrapper>
        </>
      )}
    </>
  )
}

const StyledTable = styled(Table)`
  .ant-table-thead {
    white-space: nowrap;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
  }
`
const TableWrapper = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
`

const StyledSearchBarWrapper = styled.div`
  display: flex;
  gap: 5px;
`

export default CreditNotes
