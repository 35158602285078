import styled from 'styled-components'
import FadeIn from 'src/components/styled/FadeIn'
import { API } from 'aws-amplify'
import { useSelector } from 'react-redux'

import React, { useState } from 'react'
const Feedback = () => {
  const [rating, setRating] = useState(null)
  const [feedback, setFeedback] = useState('')
  const account = useSelector(state => state.auth.account)
  const supplierId = account?.supplier[0]._id
  const handleRatingClick = value => {
    setRating(value)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (rating !== null && feedback.trim() !== '') {
      await API.post('api2', `suppliers/${supplierId}/feedback`, {
        body: {
          rating: rating,
          comment: feedback,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })
      setRating(null)
      setFeedback('')
      alert('Feedback sent successfully!')
    } else {
      alert('Please provide a rating and a feedback!')
    }
  }

  return (
    <Wrapper>
      <div style={{ maxWidth: '400px', margin: '0 auto', textAlign: 'center' }}>
        <h1>Leave Your Feedback</h1>
        <p>How would you rate your experience of OMS today?</p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '10px',
          }}
        >
          {Array.from({ length: 11 }).map((_, index) => (
            <button
              key={index}
              onClick={() => handleRatingClick(index)}
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                border:
                  rating === index ? '2px solid #007BFF' : '1px solid #0096c7',
                backgroundColor: rating === index ? '#E7F3FF' : '#fff',
                color: '#0096c7',
                cursor: 'pointer',
              }}
            >
              {index}
            </button>
          ))}
        </div>
        <p>Please let us know why you gave us that rating.</p>
        <textarea
          value={feedback}
          onChange={e => setFeedback(e.target.value)}
          placeholder='Enter your feedback...'
          style={{
            width: '100%',
            height: '100px',
            marginTop: '10px',
            padding: '10px',
            borderRadius: '8px',
            border: '1px solid #ccc',
          }}
        ></textarea>

        <ButtonContainer>
          <button onClick={handleSubmit}>Submit</button>
        </ButtonContainer>
      </div>
    </Wrapper>
  )
}

const ButtonContainer = styled.div`
  margin-top: 20px;
  padding: 10px 20px;

  button {
    background-color: ${({ theme }) => theme?.colours?.lettuceGreen || 'green'};
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      background-color: #b5d922;
      opacity: 0.8;
      cursor: pointer;
    }
  }
`

const Wrapper = styled(FadeIn)`
  .restrictShrink {
    min-width: 110px;
  }
`

export default Feedback
