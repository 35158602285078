import { createSlice } from '@reduxjs/toolkit'
import { API, Auth } from 'aws-amplify'
import Sentry from 'src/utils/sentry'
import { updateCreditsNotes } from './OrdersSlice'
import { setDataPlusOrders } from './DataplusIntagrationSlice'
import { SuccessNotification } from '../notifications/SuccessNotification'
import moment from 'moment'

const initialState = {
  receiptingOrders: [],
  filterDates: {
    start: moment().subtract(40, 'days').valueOf(),
    end: moment().valueOf(),
  },
  receiptingOrdersAlreadyExists: null,
  receiptingProducts: [],
  isLoading: false,
  receiptingLoading: false,
  venueSupplier: null,
  approvedReceipting: [],
  notApprovedReceipting: [],
  supplierProducts: [],
  updatingReceiptingProduct: false,
  history: [],
  totalCount: 0,
  pagination: { page: 1, limit: 50 },
  historyLoading: false,
  createdReceipting: [],
  orderedProductReceiptings: [],
  error: '',
  xeroProducts: [],
}

const receiptingSlice = createSlice({
  name: 'receipting',
  initialState,
  reducers: {
    setReceiptingOrders: (state, action) => {
      state.receiptingOrders = action.payload
    },
    setFilterDates: (state, action) => {
      state.filterDates = action.payload
    },
    setReceiptingExist: (state, action) => {
      state.receiptingOrdersAlreadyExists = action.payload
    },
    setReceiptingProducts: (state, action) => {
      const index = state.receiptingProducts.findIndex(order => {
        return (
          order.supplier === action.payload.supplier &&
          order.purchaseOrderNumber === action.payload.purchaseOrderNumber
        )
      })
      if (index > -1) {
        state.receiptingProducts[index] = action.payload
      } else {
        state.receiptingProducts.push(action.payload)
      }
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setReceiptingLoading: (state, action) => {
      state.receiptingLoading = action.payload
    },
    setUpdatingReceiptingProduct: (state, action) => {
      state.updatingReceiptingProduct = action.payload
    },
    updateReceiptingOrder: (state, action) => {
      const { supplierId, PONumber, savedBy, receiptingStatus, totalSpend } =
        action.payload
      state.receiptingOrders = state.receiptingOrders.map(item => {
        return item.supplier._id === supplierId &&
          item.purchaseOrderNumber === PONumber
          ? { ...item, savedBy, receiptingStatus, totalSpend }
          : item
      })
    },
    updateTotalSpendOrders: (state, action) => {
      const { supplierId, PONumber, totalSpend } = action.payload
      state.receiptingOrders = state.receiptingOrders.map(item => {
        return item.supplier._id === supplierId &&
          item.purchaseOrderNumber === PONumber
          ? { ...item, totalSpend: item.totalSpend + totalSpend }
          : item
      })
    },
    removeOldOrders: (state, action) => {
      const { supplierId, PONumber } = action.payload
      state.receiptingProducts = state.receiptingProducts.filter(item => {
        return (
          item.supplier !== supplierId && item.purchaseOrderNumber !== PONumber
        )
      })
    },
    setChecklistOrders: (state, action) => {
      const { venueSupplier, filteredChanges } = action.payload
      state.venueSupplier = venueSupplier
      const notApproved = []
      const approved = []
      filteredChanges.forEach(item => {
        item.receipting.supplierApproved !== undefined
          ? approved.push(item)
          : notApproved.push(item)
      })
      state.approvedReceipting = approved
      state.notApprovedReceipting = notApproved
    },
    handleChangeOrder: (state, action) => {
      const updatedOrder = action.payload
      state.notApprovedReceipting = state.notApprovedReceipting.map(order => {
        return order._id !== updatedOrder._id ? order : updatedOrder
      })
    },
    setSupplierProducts: (state, action) => {
      state.supplierProducts = action.payload
    },
    setReceiptingHistory: (state, action) => {
      state.history = action.payload
    },
    setHistoryLoading: (state, action) => {
      state.historyLoading = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setPagination: (state, action) => {
      state.pagination = action.payload
    },
    setCreatedReceipting: (state, action) => {
      state.createdReceipting = action.payload
    },
    setOrderedProductReceiptings: (state, action) => {
      state.orderedProductReceiptings = action.payload
    },
    setXeroProducts: (state, action) => {
      state.xeroProducts = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
  },
})

export const {
  setOrders,
  setReceiptingOrders,
  setReceiptingProducts,
  setLoading,
  setReceiptingLoading,
  updateReceiptingOrder,
  setChecklistOrders,
  handleChangeOrder,
  removeOldOrders,
  setSupplierProducts,
  setReceiptingHistory,
  setHistoryLoading,
  setTotalCount,
  setPagination,
  setCreatedReceipting,
  setOrderedProductReceiptings,
  setUpdatingReceiptingProduct,
  setError,
  setFilterDates,
  setReceiptingExist,
  setXeroProducts,
} = receiptingSlice.actions

export const fetchReceiptingOrders =
  (venueId, start, end) => async dispatch => {
    try {
      dispatch(setLoading(true))
      const normalizedStart = start.split('/').reverse().join('-')
      const normalizedEnd = end.split('/').reverse().join('-')
      const receiptingOrders = await API.get(
        'api',
        `venue/${venueId}/receipting/orders?start=${normalizedStart}&end=${normalizedEnd}`,
      )

      dispatch(setReceiptingOrders(receiptingOrders))

      const filteredOrdersForDataplus = receiptingOrders.filter(
        order => order.isOrderSavedToDataplus,
      )
      dispatch(setDataPlusOrders(filteredOrdersForDataplus))
      dispatch(setReceiptingExist(venueId))
      dispatch(setLoading(false))
    } catch (e) {
      dispatch(setLoading(false))
      console.error(e)
      Sentry.captureException(e)
      return false
    }
  }

export const fetchReceiptingProducts =
  (venueId, supplierId, PONumber) => async dispatch => {
    try {
      dispatch(setReceiptingLoading(true))
      const receiptingProducts = await API.get(
        'api',
        `venue/${venueId}/receipting/products?supplierId=${supplierId}&PONumber=${PONumber}`,
      )
      dispatch(setReceiptingProducts(receiptingProducts))
      dispatch(setReceiptingLoading(false))
    } catch (e) {
      dispatch(setLoading(false))
      console.error(e)
      Sentry.captureException(e)
      return false
    }
  }
export const fetchXeroProducts =
  (venueId, supplierId, PONumber) => async dispatch => {
    try {
      dispatch(setReceiptingLoading(true))
      const receiptingProducts = await API.get(
        'api',
        `venue/${venueId}/receipting/products?supplierId=${supplierId}&PONumber=${PONumber}`,
      )
      dispatch(setXeroProducts(receiptingProducts.orders))
      dispatch(setReceiptingLoading(false))
    } catch (e) {
      dispatch(setLoading(false))
      console.error(e)
      Sentry.captureException(e)
      return false
    }
  }

export const createNewReceipting = (data, updateInfo) => async dispatch => {
  try {
    const arr = await API.post('api', `venue/receipting/create`, { body: data })
    dispatch(setCreatedReceipting(arr))
    dispatch(removeOldOrders(updateInfo))
    const { venueId, supplierId, PONumber } = updateInfo
    dispatch(fetchReceiptingProducts(venueId, supplierId, PONumber))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}
export const deletedOrder =
  (supplierId, PONumber, venueId) => async (dispatch, getState) => {
    try {
      await API.del(
        'api',
        `receipting/deleteOrder?supplierId=${supplierId}&PONumber=${PONumber}`,
      )
      const { filterDates } = getState().receipting
      const normalizedStart = moment(filterDates.start).format('YYYY-MM-DD')
      const normalizedEnd = moment(filterDates.end).format('YYYY-MM-DD')
      await dispatch(
        fetchReceiptingOrders(venueId, normalizedStart, normalizedEnd),
      )
    } catch (e) {
      console.error('Error during delete and fetch process:', e)
      Sentry.captureException(e)
      return false
    }
  }

export const fetchChecklist =
  (venueId, supplierId, PONumber) => async dispatch => {
    try {
      dispatch(setLoading(true))
      const data = await API.get(
        'api',
        `receipting/${venueId}/${supplierId}/${PONumber}/checklist`,
      )
      dispatch(setChecklistOrders(data))
      dispatch(setLoading(false))
    } catch (e) {
      dispatch(setLoading(false))
      console.error(e)
      Sentry.captureException(e)
      return false
    }
  }

export const postChecklist = data => async dispatch => {
  try {
    dispatch(setReceiptingLoading(true))
    await API.post('api', `receipting/checklist`, { body: data })
    dispatch(setReceiptingLoading(false))
  } catch (e) {
    console.log(e)
    dispatch(setReceiptingLoading(false))
  }
}

export const fetchSupplierProducts =
  (venueId, supplierId) => async dispatch => {
    try {
      dispatch(setLoading(true))
      const products = await API.get(
        'api',
        `venue/${venueId}/${supplierId}/products`,
      )
      dispatch(setSupplierProducts(products))
      dispatch(setLoading(false))
    } catch (e) {
      dispatch(setLoading(false))
      console.error(e)
      Sentry.captureException(e)
      return false
    }
  }

export const addProduct = data => async dispatch => {
  try {
    const {
      venue: venueId,
      supplier: supplierId,
      purchaseOrderNumber: PONumber,
    } = data
    dispatch(setLoading(true))
    await API.post('api', 'receipting/addproduct', { body: data })
    dispatch(removeOldOrders({ supplierId, PONumber }))
    dispatch(fetchReceiptingProducts(venueId, supplierId, PONumber))
    // dispatch(updateTotalSpendOrders({totalSpend, supplierId, PONumber}))
    dispatch(setLoading(false))
  } catch (e) {
    dispatch(setLoading(false))
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const getHistory = orderId => async dispatch => {
  try {
    dispatch(setHistoryLoading(true))
    const history = await API.get('api', `receipting/${orderId}/history`)
    dispatch(setReceiptingHistory(history))
    dispatch(setHistoryLoading(false))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    dispatch(setHistoryLoading(false))
    return false
  }
}
export const getFullHistory = (venueId, pagination) => async dispatch => {
  try {
    dispatch(setHistoryLoading(true))
    const result = await API.get(
      'api',
      `receipting/${venueId}/history-all?page=${pagination.page}&limit=${pagination.limit}`,
    )
    dispatch(setReceiptingHistory(result.receiptingArr))
    dispatch(setTotalCount(result.receiptingTotalCount))
    dispatch(setPagination(pagination))
    dispatch(setHistoryLoading(false))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    dispatch(setHistoryLoading(false))
    return false
  }
}

export const clearFullHistory = () => async dispatch => {
  dispatch(setReceiptingHistory([]))
  dispatch(setPagination({ page: 1, limit: 50 }))
  dispatch(setTotalCount(0))
}

export const fetchReceiptingsForOrderedProducts =
  () => async (dispatch, getState) => {
    const { orders } = getState()
    const receiptingIds = orders.filtered
      .filter(order => order.receipting !== undefined)
      .map(order => order.receipting)
    await API.post('api', `receipting/orderedProducts`, {
      body: { receiptingIds: receiptingIds },
    }).then(data => {
      dispatch(setOrderedProductReceiptings(data))
      return data
    })
  }

export const makeOrderResolved = receiptingId => async dispatch => {
  try {
    const updateReceipting = await API.put(
      'api',
      `receipting/credits-history/${receiptingId}/orderResolved`,
    )
    dispatch(updateCreditsNotes(updateReceipting))
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return false
  }
}

export const updateReceiptingProduct = (productId, data) => async dispatch => {
  try {
    dispatch(setUpdatingReceiptingProduct(true))
    const updatedReceiptingProduct = await API.patch(
      'api',
      `product/${productId}`,
      { body: data },
    )

    SuccessNotification({ message: updatedReceiptingProduct.message })

    dispatch(setUpdatingReceiptingProduct(false))
    console.log('updatedReceiptingProduct', updatedReceiptingProduct)
  } catch (e) {
    dispatch(setUpdatingReceiptingProduct(false))
    SuccessNotification({ message: 'Something went wrong!!!' })
    console.log('Error in updateReceiptingProduct', e)
  }
}
export const createXeroBill =
  (billData, file, venueId, organizationId) => async dispatch => {
    try {
      dispatch(setLoading(true))
      const session = await Auth.currentSession()
      const token = session.getAccessToken().getJwtToken()
      const formData = new FormData()
      formData.append('billData', JSON.stringify(billData))
      formData.append('venueId', venueId)
      formData.append('organizationId', organizationId)
      formData.append('file', file)

      const response = await fetch(
        `${process.env.REACT_APP_EXPRESS_API_ENDPOINT}venue/createXeroBill`,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `${token}`,
          },
        },
      )
      if (response.status === 201) {
        dispatch(setLoading(false))
        alert('Order sent to Xero successfully')
        window.location.href = `/v/${venueId}/purchasing/receipting`
      }
    } catch (error) {
      console.error('Error creating Xero bill or attaching file:', error)
    }
  }

export default receiptingSlice
